import { WeatherMetrics } from "@hygo/shared/models";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface MetricsProductListProps {
	metrics: WeatherMetrics;
	productMetrics: (keyof WeatherMetrics)[];
}

const Wrapper = styled.div`
	background-color: var(--night-5);
	padding: 8px 16px;
	border-radius: 4px;
	margin: 16px 0;
`;

const MetricWrapper = styled.div`
	margin-bottom: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&:last-child {
		margin-bottom: 0;
	}
`;

const Category = styled.h5`
	color: var(--night-50);
`;

const Label = styled.h5`
	color: var(--night-100);
`;

const Unit = styled.span`
	color: var(--lake-100);
`;

const MetricsProductList = ({ metrics, productMetrics }: MetricsProductListProps): JSX.Element => {
	const { t } = useTranslation();

	if (!productMetrics || productMetrics.length === 0) return null;

	return (
		<Wrapper>
			{productMetrics?.map((metric) => {
				if (metrics?.[metric] || metrics?.[metric] === 0)
					return (
						<MetricWrapper key={metric}>
							<Label>
								{t(`tank.productMetrics.${metric}.label`)}:{" "}
								<Unit>
									{(metrics?.[metric as keyof WeatherMetrics] as number)?.toFixed(0)}{" "}
									{t(`tank.productMetrics.${metric}.unit`)}
								</Unit>
							</Label>

							<Category>{t(`tank.productMetrics.${metric}.category`)}</Category>
						</MetricWrapper>
					);
				return null;
			})}
		</Wrapper>
	);
};

export default MetricsProductList;
