import { Target } from "@hygo/shared/models";
import styled from "styled-components";

interface TargetIconProps {
	fill: string;
	height?: number;
	target: Target;
	width?: number;
}

const Wrapper = styled.div<Omit<TargetIconProps, "target">>`
	height: ${(props) => props.height}px;
	width: ${(props) => props.width}px;
	svg {
		fill: ${(props) => props.fill};
		height: 100%;
		width: 100%;
	}
`;

const TargetIcon = ({ fill, height = 24, target, width = 24 }: TargetIconProps): JSX.Element => {
	if (!target?.svg) return null;
	return <Wrapper dangerouslySetInnerHTML={{ __html: target.svg }} fill={fill} height={height} width={width} />;
};

export default TargetIcon;
