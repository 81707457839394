import { ProductUnit } from "@hygo/shared/models";

const conversionCoeff: Record<string, number> = {
	[ProductUnit.GRAMS_PER_HA]: 0.001,
	[ProductUnit.KILOGRAMS_PER_HA]: 1,
	[ProductUnit.LITER_PER_HA]: 1
};

export const computeDosesSum = (
	products: { quantityPerHA: number; totalQuantity: number; unit: ProductUnit }[],
	totalAreaHA: number,
	isPellet: boolean
): number => {
	let result = products;
	if (!isPellet) result = products.filter((p) => p.unit === ProductUnit.LITER_PER_HA);
	return result.reduce((sum, { quantityPerHA, totalQuantity, unit }) => {
		if (quantityPerHA) return sum + conversionCoeff[unit] * quantityPerHA;
		if (totalQuantity)
			return totalAreaHA
				? sum + (conversionCoeff[unit] * totalQuantity) / totalAreaHA
				: sum + conversionCoeff[unit] * totalQuantity;
		return 0;
	}, 0);
};
