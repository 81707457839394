import { Calendar, WaterFill } from "@hygo/shared/icons";
import { Country } from "@hygo/shared/models";
import { COLORS, formatDateToLocale, formatJSDateInHours } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ButtonCluster from "../../ButtonCluster";
import MultiActionsButton from "../../MultiActionsButton";

interface IrrigationSectionProps {
	countryCode: Country;
	endTime: string;
	quantity: number;
	startTime: string;
}

const Wrapper = styled.section`
	padding: 16px;
	background-color: var(--white-100);
	border-radius: 4px;
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 16px;
`;

const InputsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const IrrigationSection = ({ countryCode, endTime, quantity, startTime }: IrrigationSectionProps): JSX.Element => {
	const { i18n, t } = useTranslation();

	return (
		<Wrapper>
			<TitleWrapper>
				<WaterFill fill={COLORS.LAKE[100]} height={20} width={20} />
				<h4>{t("glossary.details")}</h4>
			</TitleWrapper>
			<InputsWrapper>
				<MultiActionsButton>
					<ButtonCluster
						disabled
						Icon={<Calendar fill={COLORS.LAKE[100]} height={20} width={20} />}
						label={t("inputs.startTime.label")}
						onClick={() => null}
						value={`${formatDateToLocale(new Date(startTime), `${i18n.resolvedLanguage}-${countryCode}`)} - ${formatJSDateInHours(new Date(startTime))}h`}
					/>
					<ButtonCluster
						disabled
						Icon={<Calendar fill={COLORS.LAKE[100]} height={20} width={20} />}
						label={t("inputs.endTime.label")}
						onClick={() => null}
						value={`${formatDateToLocale(new Date(endTime), `${i18n.resolvedLanguage}-${countryCode}`)} - ${formatJSDateInHours(new Date(endTime))}h`}
					/>
				</MultiActionsButton>
				<MultiActionsButton>
					<ButtonCluster
						disabled
						Icon={<WaterFill fill={COLORS.LAKE[100]} height={20} width={20} />}
						label={t("inputs.quantity.label")}
						onClick={() => null}
						value={`${quantity} ${t("units.millimeters")}`}
					/>
				</MultiActionsButton>
			</InputsWrapper>
		</Wrapper>
	);
};

export default IrrigationSection;
