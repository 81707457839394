import { RecycleCrop, WaterFill } from "@hygo/shared/icons";
import { Country, Crop, DenormalizedField } from "@hygo/shared/models";
import {
	COLORS,
	computeColorFromConditions,
	formatDateToLocale,
	formatJSDateInHours,
	hexToRGBA
} from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CropIcon from "../../CropIcon";
import ParcelSVG from "../../ParcelSVG";
import TooltipBubble from "../../TooltipBubble";

interface AccordionSummaryProps {
	countryCode: Country;
	crops: Crop[];
	endTime: string;
	fields: DenormalizedField[];
	selectedFields: DenormalizedField[];
	startTime: string;
}

const Icon = styled.svg<{ width: number }>`
	min-width: ${(props) => props.width}px;
`;

const CategoryWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const CropsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const CropText = styled.h5`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ProductFamilyText = styled.h4`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const CropItem = styled.div`
	padding: 8px 16px;
	display: flex;
	align-items: center;
	gap: 8px;
	justify-content: center;
	border-radius: 30px;
	background-color: var(--night-5);
`;

const StyledToolTipBubble = styled(TooltipBubble)`
	top: 42px;
	z-index: 1;
	left: -7px;
`;

const FieldsTooltipBubble = styled(StyledToolTipBubble)`
	top: 50px;
	left: -4px;
`;

const CropTooltipBubble = styled(StyledToolTipBubble)`
	top: 50px;
	left: -2px;
`;

const Tooltip = styled.div`
	position: relative;
	cursor: pointer;
	&:hover ${StyledToolTipBubble} {
		display: block;
	}
`;

const TooltipFieldsWrapper = styled(Tooltip)`
	position: relative;
	cursor: pointer;
	flex: 1;
	width: 0;
	&:hover ${FieldsTooltipBubble} {
		display: block;
	}
`;

const FieldsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	overflow: hidden;
`;

const Slot = styled.h5`
	white-space: nowrap;
`;

const AccordionSummary = ({
	countryCode,
	crops,
	endTime,
	fields,
	selectedFields,
	startTime
}: AccordionSummaryProps): JSX.Element => {
	const { i18n, t } = useTranslation();

	const cropIds = [...new Set(fields?.map((f) => f.cropId))];
	const firstCrop = crops.find((c) => c.id === cropIds?.[0]);

	const getCropsList = (): string => {
		const cropsNames = cropIds.map((cId) => {
			const crop = crops.find((c) => c.id === cId);
			return crop?.name;
		});
		return cropsNames.join(", ");
	};

	return (
		<>
			<Slot>
				{formatDateToLocale(new Date(startTime), `${i18n.resolvedLanguage}-${countryCode}`, false)}{" "}
				{t("time.to")} {formatJSDateInHours(new Date(startTime))}h -{" "}
				{formatDateToLocale(new Date(endTime), `${i18n.resolvedLanguage}-${countryCode}`, false)} {t("time.to")}{" "}
				{formatJSDateInHours(new Date(endTime))}h
			</Slot>
			<CategoryWrapper>
				<WaterFill fill={COLORS.NIGHT[100]} height={20} width={20} />
				<ProductFamilyText>{t("glossary.irrigation")}</ProductFamilyText>
			</CategoryWrapper>

			<CropsWrapper>
				{cropIds?.length > 1 ? (
					<Tooltip>
						<CropItem>
							<Icon as={RecycleCrop} fill={COLORS.NIGHT[100]} height={16} width={16} />
							<CropText>{t("components.taskCard.accordionSummary.multiCropsLabel")}</CropText>
						</CropItem>
						<CropTooltipBubble text={getCropsList()} />
					</Tooltip>
				) : (
					<CropItem>
						<CropIcon crop={firstCrop} fill={COLORS.NIGHT[100]} height={16} width={16} />
						<CropText>{firstCrop?.name}</CropText>
					</CropItem>
				)}
			</CropsWrapper>
			<TooltipFieldsWrapper>
				<FieldsWrapper>
					{fields?.map((field) => {
						const isSelected = selectedFields.map((f) => f.id).includes(field.id);
						const crop = crops?.find((c) => c.id === field.cropId);
						if (!field.svg) return null;
						return (
							<Icon
								as={ParcelSVG}
								fill={
									selectedFields?.length === 0 || isSelected
										? computeColorFromConditions(
												field?.condition,
												"FIELD",
												hexToRGBA(crop?.color, 0.5, COLORS.LAKE[50])
											)
										: computeColorFromConditions(
												field?.condition,
												"BG_COLOR",
												hexToRGBA(crop?.color, 0.1, COLORS.LAKE[10])
											)
								}
								height={40}
								key={field.id}
								path={field.svg}
								stroke={
									selectedFields?.length === 0 || isSelected
										? computeColorFromConditions(
												field?.condition,
												"SLOT",
												hexToRGBA(crop?.color, 1, COLORS.LAKE[100])
											)
										: computeColorFromConditions(
												field?.condition,
												"FIELD",
												hexToRGBA(crop?.color, 0.25, COLORS.LAKE[25])
											)
								}
								width={40}
							/>
						);
					})}
				</FieldsWrapper>
				<FieldsTooltipBubble text={t("components.taskCard.field", { count: fields?.length })} />
			</TooltipFieldsWrapper>
		</>
	);
};

export default AccordionSummary;
