import { COLORS } from "@hygo/shared/utils";
import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { FC, SVGProps } from "react";
import styled from "styled-components";

import Button from "../../Button";

const Overlay = styled(Dialog.Overlay)`
	background: rgba(0 0 0 / 0.5);
	position: fixed;
	inset: 0;
	animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
	@keyframes overlayShow {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const Wrapper = styled(Dialog.Content)`
	border-radius: 16px;
	padding: 32px 64px;
	max-height: 80vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 50vw;
	animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
	position: fixed;
	background-color: var(--white-100);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@keyframes contentShow {
		from {
			opacity: 0;
			transform: translate(-50%, -48%) scale(0.96);
		}
		to {
			opacity: 1;
			transform: translate(-50%, -50%) scale(1);
		}
	}
`;

const Title = styled.h2`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

const Content = styled.div<{ $withMarginBottom: boolean }>`
	margin-top: 16px;
	overflow: auto;
	margin-bottom: ${(props) => (props.$withMarginBottom ? "16px" : 0)};
`;

const Ctas = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const StyledButton = styled(Button)`
	flex: 1;
`;

interface BaseModalPropsType {
	cancelBtnText?: string;
	children: JSX.Element | JSX.Element[];
	closeAfterConfirm?: boolean;
	colorCancelBtn?: Record<number, string>;
	colorConfirmBtn?: Record<number, string>;
	colorCustomBtn?: Record<number, string>;
	confirmBtnDisabled?: boolean;
	confirmBtnIcon?: FC<SVGProps<SVGSVGElement>>;
	confirmBtnLoading?: boolean;
	confirmBtnText?: string;
	customBtnIcon?: FC<SVGProps<SVGSVGElement>>;
	customBtnText?: string;
	hideModal: () => void;
	modalVisible: boolean;
	onCancel?: () => void;
	onConfirm?: () => void;
	onCustom?: () => void;
	title: string;
}
const BaseModal = ({
	cancelBtnText,
	children,
	closeAfterConfirm = true,
	colorCancelBtn = COLORS.SMOKE,
	colorConfirmBtn,
	colorCustomBtn = COLORS.LAKE,
	confirmBtnDisabled,
	confirmBtnIcon,
	confirmBtnLoading,
	confirmBtnText,
	customBtnIcon,
	customBtnText,
	hideModal,
	modalVisible,
	onCancel,
	onConfirm,
	onCustom,
	title
}: BaseModalPropsType): JSX.Element => {
	const onClickCancel = (): void => {
		onCancel && onCancel();
		hideModal();
	};
	const onClickConfirm = (): void => {
		onConfirm && onConfirm();
		if (closeAfterConfirm) hideModal();
	};

	const onClickOnCenterButton = (): void => {
		onCustom && onCustom();
		hideModal();
	};

	return (
		<Dialog.Root aria-labelledby="alert-dialog-title" modal onOpenChange={hideModal} open={modalVisible}>
			<Dialog.Portal>
				<Overlay />
				<Wrapper aria-describedby={undefined}>
					<VisuallyHidden.Root asChild>
						<Dialog.Title>{title}</Dialog.Title>
					</VisuallyHidden.Root>
					<VisuallyHidden.Root asChild>
						<Dialog.Description>{title}</Dialog.Description>
					</VisuallyHidden.Root>
					<Title>{title}</Title>

					<Content $withMarginBottom={!!confirmBtnText} aria-describedby={undefined}>
						{children}
					</Content>
					<Ctas>
						{cancelBtnText && onCancel && (
							<StyledButton
								color={colorCancelBtn}
								inverted
								onClick={onClickCancel}
								text={cancelBtnText}
							/>
						)}
						{customBtnText && onCustom && (
							<StyledButton
								color={colorCustomBtn}
								fillIcon
								Icon={customBtnIcon}
								onClick={onClickOnCenterButton}
								text={customBtnText}
							/>
						)}
						{confirmBtnText && (
							<StyledButton
								color={colorConfirmBtn}
								disabled={confirmBtnDisabled}
								fillIcon
								Icon={confirmBtnIcon}
								loading={confirmBtnLoading}
								onClick={onClickConfirm}
								text={confirmBtnText}
							/>
						)}
					</Ctas>
				</Wrapper>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

export default BaseModal;
