import { COLORS, GRADIENTS } from "@hygo/shared/utils";
import { createGlobalStyle } from "styled-components";

const styled = { createGlobalStyle };

const GlobalStyles = styled.createGlobalStyle`
	:root {
		--white-100: ${COLORS.WHITE[100]};
		--white-50: ${COLORS.WHITE[50]};
		--white-25: ${COLORS.WHITE[100]};
		--white-80: ${COLORS.WHITE[80]};
		--white-10: ${COLORS.WHITE[10]};
		--lake-100: ${COLORS.LAKE[100]};
		--lake-hover: ${COLORS.LAKE[200]};
		--lake-50: ${COLORS.LAKE[50]};
		--lake-25: ${COLORS.LAKE[25]};
		--lake-10: ${COLORS.LAKE[10]};
		--disabled-100: ${COLORS.DISABLED[100]};
		--disabled-10: ${COLORS.DISABLED[10]};
		--sky-100: ${COLORS.SKY[100]};
		--sky-50: ${COLORS.SKY[50]};
		--sky-25: ${COLORS.SKY[25]};
		--night-100: ${COLORS.NIGHT[100]};
		--night-90: ${COLORS.NIGHT[90]};
		--night-50: ${COLORS.NIGHT[50]};
		--night-25: ${COLORS.NIGHT[25]};
		--night-10: ${COLORS.NIGHT[10]};
		--night-5: ${COLORS.NIGHT[5]};
		--tangerine-100: ${COLORS.TANGERINE[100]};
		--tangerine-hover: ${COLORS.TANGERINE[200]};
		--tangerine-50: ${COLORS.TANGERINE[50]};
		--tangerine-25: ${COLORS.TANGERINE[25]};
		--tangerine-10: ${COLORS.TANGERINE[10]};
		--gaspacho-100: ${COLORS.GASPACHO[100]};
		--gaspacho-hover: ${COLORS.GASPACHO[200]};
		--gaspacho-50: ${COLORS.GASPACHO[50]};
		--gaspacho-25: ${COLORS.GASPACHO[25]};
		--sun-100: ${COLORS.SUN[100]};
		--sun-50: ${COLORS.SUN[50]};
		--sun-25: ${COLORS.SUN[25]};
		--grass-200: ${COLORS.GRASS[200]};
		--grass-100: ${COLORS.GRASS[100]};
		--grass-50: ${COLORS.GRASS[50]};
		--grass-10: ${COLORS.GRASS[10]};
		--smoke-100: ${COLORS.SMOKE[100]};
		--gradient-light-grey: ${`linear-gradient(180deg, ${GRADIENTS.LIGHT_GREY[0]} 0%, ${GRADIENTS.LIGHT_GREY[1]} 100%)`};
		--gradient-aluminimum: ${`linear-gradient(90.61deg, ${GRADIENTS.ALUMINIMUM[0]} 3.3%, ${GRADIENTS.ALUMINIMUM[1]} 100%)`};
		--gradient-background-1: ${`linear-gradient(154.55deg, ${GRADIENTS.BACKGROUND_1[0]} 5.14%, ${GRADIENTS.BACKGROUND_1[1]} 97.24%)`};
		--gradient-background-2: ${`linear-gradient(180deg, ${GRADIENTS.BACKGROUND_2[0]} 0%, ${GRADIENTS.BACKGROUND_2[1]} 100%)`};
		--gradient-lake: ${`linear-gradient(117.16deg, ${GRADIENTS.LAKE_GRAD[0]} 3.97%, ${GRADIENTS.LAKE_GRAD[1]} 100%)`};
		--gradient-tangerine: ${`linear-gradient(242.43deg, ${GRADIENTS.TANGERINE_GRAD[0]} 0%, ${GRADIENTS.TANGERINE_GRAD[1]} 100%)`};
		--gradient-skeleton: ${`linear-gradient(287.43deg, hsla(180, 13%, 95%, 1) 11.95%, hsla(190, 14%, 91%, 1) 50%, hsla(180, 13%, 95%, 1) 88.05%)`};
		--12px: 0.75rem;
		--14px: 0.875rem;
		--16px: 1rem;
		--20px: 1.25rem;
		--24px: 1.5rem;
		--36px: 2.25rem;
	}
	/*
    1. Use a more-intuitive box-sizing model.
  */
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}
	/*
    2. Remove default margin
  */
	* {
		margin: 0;
	}
	/*
    3. Allow percentage-based heights in the application
  */

	html,
	body,
	#root {
		height: 100%;
	}

	body {
		overflow: hidden;
		scroll-behavior: smooth;
	}
	/*
    Typographic tweaks!
    4. Add accessible line-height
    5. Improve text rendering
  */
	body {
		line-height: 1.5;
		-webkit-font-smoothing: antialiased;
		/* overflow: hidden; */
	}
	/*
    6. Improve media defaults
  */
	img,
	picture,
	video,
	canvas,
	svg {
		display: block;
		max-width: 100%;
	}
	/*
    7. Remove built-in form typography styles
  */
	input,
	button,
	textarea,
	select {
		font: inherit;
	}
	/*
    8. Avoid text overflows
  */
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		overflow-wrap: break-word;
	}
	/*
    9. Create a root stacking context
  */
	#root {
		isolation: isolate;
		overflow: hidden;
	}

	button {
		padding: 0;
		border: none;
		background-color: unset;
		cursor: pointer;
		text-align: unset;
		align-items: unset;
		color: unset;
	}

	.pac-logo::after {
		display: none;
	}

	.pac-item {
		padding: 4px;
		span {
			font-family: "Nunito Regular";
			font-size: var(--14px);
			color: var(--night-50);
			font-weight: normal;
		}
		.pac-item-query {
			font-family: "Nunito Bold";
			font-size: var(--14px);
			color: var(--night-100);
			font-weight: normal;
			.pac-matched {
				font-family: "Nunito Bold";
				font-size: var(--14px);
				color: var(--night-100);
				font-weight: normal;
			}
		}
	}
	/* @media (max-width: 740px) {
		body {
			overflow: hidden;
		}
	} */
`;

export default GlobalStyles;
