import { Chevron } from "@hygo/shared/icons";
import { useEffect, useState } from "react";
import styled from "styled-components";

const TRANSITION_DURATION = 100;

const Wrapper = styled.div<{ $needCheck: boolean }>`
	--radius: 8px;
	background-color: var(--white-100);
	border-radius: var(--radius);
	border: ${(props) => (props.$needCheck ? "1px solid var(--gaspacho-100)" : "unset")};
`;

const ChevronIcon = styled(Chevron)`
	transition: transform ${TRANSITION_DURATION}ms ease-out;
`;

export const AccordionTitle = styled.div<{ open: boolean }>`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	border-radius: ${(props) => (props.open ? "var(--radius) var(--radius) 0 0" : "var(--radius)")};
	${ChevronIcon} {
		transform: rotate(${(props) => (props.open ? 0 : -90)}deg);
	}
	&:hover {
		background-color: var(--night-10);
	}
`;

export const AccordionContent = styled.div`
	display: grid;
	border-radius: 0 0 var(--radius) var(--radius);
	grid-template-rows: 1fr;
	transition: grid-template-rows ${TRANSITION_DURATION}ms ease-in-out;
	&[aria-expanded="true"] {
		transition: grid-template-rows ${TRANSITION_DURATION}ms cubic-bezier(0, 1, 0, 1);
		grid-template-rows: 0fr;
	}
`;

const ContentWrapper = styled.div`
	overflow: hidden;
`;

const InnerContent = styled.div`
	padding: 8px 16px;
`;

interface AccordionProps {
	chevronColor: string;
	children: JSX.Element | JSX.Element[];
	className?: string;
	needCheck?: boolean;
	onOpen?: () => void;
	opened?: boolean;
	summary: JSX.Element;
}

const Accordion = ({
	chevronColor,
	children,
	className,
	needCheck,
	onOpen,
	opened,
	summary
}: AccordionProps): JSX.Element => {
	const [open, setOpen] = useState(opened);

	const onClick = (): void => {
		setOpen(!open);
		setTimeout(() => {
			if (!open) onOpen && onOpen();
		}, TRANSITION_DURATION);
	};

	useEffect(() => {
		setOpen(opened);
	}, [opened]);

	return (
		<Wrapper $needCheck={needCheck} className={className}>
			<AccordionTitle onClick={onClick} onKeyDown={onClick} open={open} role="button" tabIndex={0}>
				{summary}
				<ChevronIcon fill={chevronColor} height={16} width={16} />
			</AccordionTitle>

			<AccordionContent aria-expanded={!open}>
				<ContentWrapper>
					<InnerContent>{children}</InnerContent>
				</ContentWrapper>
			</AccordionContent>
		</Wrapper>
	);
};

export default Accordion;
