import { Parcelles } from "@hygo/shared/icons";
import { Crop, DenormalizedField, Tank, WindSpeedUnity } from "@hygo/shared/models";
import { COLORS, convertToHa } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import FieldCard from "./FieldCard";

interface FieldsSectionProps {
	crops: Crop[];
	fields: DenormalizedField[];
	selectedFields: DenormalizedField[];
	tankIndications: Tank;
	windSpeedUnity: WindSpeedUnity;
}

const Wrapper = styled.section``;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const FieldCardWrapper = styled.div`
	overflow-x: auto;
	margin: 0 -16px;
	padding: 8px 16px 16px 16px;
	white-space: nowrap;
`;

const FieldsSection = ({
	crops,
	fields,
	selectedFields,
	tankIndications,
	windSpeedUnity
}: FieldsSectionProps): JSX.Element => {
	const { t } = useTranslation();
	const totalArea = fields?.reduce((current, nextField) => current + nextField.fieldArea, 0);

	return (
		<Wrapper>
			<TitleWrapper>
				<Parcelles fill={COLORS.LAKE[100]} height={20} width={20} />
				<h4>
					{t("components.fieldsReportCard.title")} ({`${convertToHa(totalArea)} ${t("units.hectare")}`})
				</h4>
			</TitleWrapper>
			{!!fields?.length && (
				<FieldCardWrapper>
					{fields.map((field) => {
						const isSelected = selectedFields?.map((f) => f.id).includes(field.id);
						return (
							<FieldCard
								crops={crops}
								field={field}
								isSelected={isSelected || selectedFields?.length === 0}
								key={field?.id}
								tankIndications={tankIndications}
								windSpeedUnity={windSpeedUnity}
							/>
						);
					})}
				</FieldCardWrapper>
			)}
		</Wrapper>
	);
};

export default FieldsSection;
