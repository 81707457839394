import { Crop } from "@hygo/shared/models";
import styled from "styled-components";

interface CropIconProps {
	crop: Crop;
	fill: string;
	height?: number;
	width?: number;
}

const Wrapper = styled.div<Omit<CropIconProps, "crop">>`
	svg {
		min-height: ${(props) => props.height}px;
		min-width: ${(props) => props.width}px;
		fill: ${(props) => props.fill};
	}
`;

const CropIcon = ({ crop, fill, height = 24, width = 24 }: CropIconProps): JSX.Element => {
	if (!crop?.svg) return null;
	return <Wrapper dangerouslySetInnerHTML={{ __html: crop.svg }} fill={fill} height={height} width={width} />;
};

export default CropIcon;
