import { UserContext } from "@hygo/shared/contexts";
import { ExportTasksModalPropsType } from "@hygo/shared/modals";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CardWithImage from "../../CardWithImage";
import SimpleButton from "../../SimpleButton";
import BaseModal from "../BaseModal";

const Subtitle = styled.h4`
	text-align: center;
	color: var(--night-50);
`;

const Link = styled(SimpleButton)`
	text-align: center;
	width: unset !important;
`;

const CardsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 40px;
	margin: 16px 0;
	text-decoration: none;
`;

const ExportTasksModal = ({
	hideModal,
	isGeofoliaExportable,
	modalVisible,
	onConfirm,
	selectedSeason
}: ExportTasksModalPropsType): JSX.Element => {
	const { i18n, t } = useTranslation();

	const { user } = useContext(UserContext);

	const onClick = (format: "dap" | "xlsx"): void => {
		onConfirm(format);
		hideModal();
	};

	const onClickHelp = (): void => {
		window.open(
			`https://support.alvie.fr/${i18n.resolvedLanguage}/articles/8703395-exportez-vos-interventions-vers-geofolia`,
			"_blank"
		);
	};

	return (
		<BaseModal hideModal={hideModal} modalVisible={modalVisible} title={t("modals.exportTasks.title")}>
			<Subtitle>
				{t("modals.exportTasks.subtitle", {
					currentYear: selectedSeason.startBefore.getFullYear(),
					firstName: user.firstName
				})}
			</Subtitle>
			<CardsWrapper>
				<CardWithImage
					asset={{ image: "/assets/images/tasks/export-excel.png" }}
					imageWidth={215}
					onClick={() => onClick("xlsx")}
					subTitle={t("modals.exportTasks.excel.body")}
					title={t("modals.exportTasks.excel.title")}
				/>

				<CardWithImage
					asset={{ image: "/assets/images/tasks/export-edi.png" }}
					disabled={!isGeofoliaExportable}
					imageWidth={215}
					onClick={() => onClick("dap")}
					subTitle={t("modals.exportTasks.edi.body")}
					title={t("modals.exportTasks.edi.title")}
				/>
			</CardsWrapper>

			<Link onClick={onClickHelp} text={t("modals.exportTasks.linkToTutorial")} />
		</BaseModal>
	);
};
export default ExportTasksModal;
