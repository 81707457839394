import { Barn } from "@hygo/shared/icons";
import { AmplitudeEvent, Crop, Farm, Field } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { DashboardMode } from "@hygo/web/models";
import { Dispatch, RefObject, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Loader from "../Loader";
import ManagerEmptyState from "../ManagerEmptyState";
import FieldsTreeView from "./components/FieldsTreeView";
import Footer from "./components/Footer";

interface FieldsListCardProps {
	adminWithoutUserSelected?: boolean;
	crops: Crop[];
	farms: Farm[];
	fields: Field[];
	fieldsRef?: { [x: number]: RefObject<HTMLDivElement> };
	handleFieldSelection: (data: {
		centerMap: boolean;
		field: Field;
		overrideActiveFields: Field[];
		overrideNewSelectedFields: Field[];
		selection: boolean;
	}) => void;
	loading: boolean;
	multiSelectionEnabled: boolean;
	resetSelection?: () => void;
	selectedFields: Field[];
	selectFieldEvent: AmplitudeEvent;
	setCurrentMode?: Dispatch<SetStateAction<DashboardMode>>;
	showEmptyStates: boolean;
	withFooter: boolean;
	withNeedCheck: boolean;
}

const DisableFilterButton = styled.div<{ $hasSelection: boolean }>`
	margin: 0 16px 8px 16px;
	padding: 8px 16px;
	background: ${(props) => (props.$hasSelection ? "var(--white-100)" : "var(--lake-25)")};
	color: ${(props) => (props.$hasSelection ? "var(--night-100)" : "var(--lake-hover)")};
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	gap: 8px;
	align-items: center;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
`;

const FieldsListCard = ({
	adminWithoutUserSelected,
	crops,
	farms,
	fields,
	fieldsRef,
	handleFieldSelection,
	loading,
	multiSelectionEnabled,
	resetSelection,
	selectedFields,
	selectFieldEvent,
	setCurrentMode,
	showEmptyStates,
	withFooter,
	withNeedCheck
}: FieldsListCardProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			{fields?.length === 0 || loading || adminWithoutUserSelected ? (
				<>
					{loading && <Loader />}
					{!loading && showEmptyStates && (
						<>
							{/* For users without farms */}
							{!farms?.length && !adminWithoutUserSelected && (
								<ManagerEmptyState
									asset={<Barn fill={COLORS.NIGHT[100]} height={48} width={48} />}
									subtitle={t("components.fieldsListCard.emptyStates.noDefaultFarm.subtitle")}
									title={t("components.fieldsListCard.emptyStates.noDefaultFarm.title")}
								/>
							)}
							{/* For users with farms and no fields */}
							{farms?.length > 0 && !adminWithoutUserSelected && (
								<>
									<ManagerEmptyState
										asset={
											<img
												alt={t("components.fieldsListCard.emptyStates.noFields.title")}
												src="/assets/images/crops/empty-fields.png"
												width={120}
											/>
										}
										subtitle={t("components.fieldsListCard.emptyStates.noFields.subtitle")}
										title={t("components.fieldsListCard.emptyStates.noFields.title")}
									/>
									{withFooter && (
										<Footer
											handleFieldSelection={handleFieldSelection}
											setCurrentMode={setCurrentMode}
										/>
									)}
								</>
							)}
							{/* For admins / TC */}
							{adminWithoutUserSelected && (
								<ManagerEmptyState
									asset={
										<img
											alt={t("components.fieldsListCard.emptyStates.noUserSelected.title")}
											src="/assets/images/crops/select-agri.png"
											width={200}
										/>
									}
									subtitle={t("components.fieldsListCard.emptyStates.noUserSelected.subtitle")}
									title={t("components.fieldsListCard.emptyStates.noUserSelected.title")}
								/>
							)}
						</>
					)}
				</>
			) : (
				<>
					{resetSelection && (
						<DisableFilterButton $hasSelection={selectedFields?.length > 0} onClick={resetSelection}>
							<Barn
								fill={selectedFields?.length > 0 ? COLORS.NIGHT[100] : COLORS.LAKE[200]}
								height={20}
								width={20}
							/>
							<h4>{t("components.fieldsListCard.disableFilterBtn")}</h4>
						</DisableFilterButton>
					)}
					<FieldsTreeView
						crops={crops}
						fields={fields}
						fieldsRef={fieldsRef}
						handleFieldSelection={handleFieldSelection}
						multiSelectionEnabled={multiSelectionEnabled}
						selectedFields={selectedFields}
						selectFieldEvent={selectFieldEvent}
						withNeedCheck={withNeedCheck}
					/>
					{withFooter && (
						<Footer handleFieldSelection={handleFieldSelection} setCurrentMode={setCurrentMode} />
					)}
				</>
			)}
		</Wrapper>
	);
};
export default FieldsListCard;
