import { AmplitudeContext } from "@hygo/shared/amplitude";
import { Chevron, Close, Plus } from "@hygo/shared/icons";
import { AmplitudeEvent, Item } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Control, FieldPath, FieldValues, RegisterOptions, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Loader from "../Loader/Loader";
import SearchInput from "../SearchInput/SearchInput";

interface Option extends Item {
	subtitle?: string;
}

interface SelectInputProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
	asyncFilter?: (inputValue: string) => Promise<Option[]>;
	borderRadius?: string;
	className?: string;
	clearable?: boolean;
	control: Control<TFieldValues>;
	ctaAction?: { label: string; onClick: () => void };
	disabled?: boolean;
	event?: AmplitudeEvent;
	flex?: boolean;
	height?: number;
	hint?: string;
	initialOptions: Option[];
	label?: string;
	name: TName;
	onCustomChange?: (data: Option) => void;
	onOpenCallback?: (v: boolean) => void;
	placeholder: JSX.Element | string;
	rules?: Omit<RegisterOptions, "deps">;
	searchable?: boolean;
	shouldUnregister?: boolean;
	theme: "dark" | "light";
}

const Label = styled.label<{ $error: boolean }>`
	display: block;
	font-size: var(--14px);
	font-family: "Nunito Bold";
	color: ${(props) => (props.$error ? "var(--gaspacho-100)" : "var(--night-100)")};
`;

const Error = styled.p`
	color: var(--gaspacho-100);
`;

const DropDownContainer = styled.div<{ height: number }>`
	position: relative;
	flex: 1;

	--height: ${(props) => props.height}px;
`;

const DropDownListContainer = styled.div<{ $backgroundColor: string; $borderColor: string }>`
	position: absolute;
	z-index: 3;
	width: 100%;
	margin-top: 4px;
	border-radius: 4px;
	background: ${(props) => props.$backgroundColor};
	border: 1px solid ${(props) => props.$borderColor};
	box-shadow:
		0px 0.8px 1.5px rgba(0, 83, 94, 0.1),
		0px 6px 12px rgba(0, 83, 94, 0.1);
`;

const ListItem = styled.div<{ $isLightTheme: boolean; color: string }>`
	padding: 0 8px;
	height: var(--height);
	align-items: center;
	display: flex;
	cursor: pointer;
	color: ${(props) => props.color};
	gap: 4px;
	&:hover {
		background: ${(props) => (props.$isLightTheme ? "var(--night-5)" : "var(--white-50)")};
	}
`;

const DropdownList = styled.div`
	overflow: auto;
	max-height: 300px;
`;

const DropDownHeader = styled.div<{
	$backgroundColor: string;
	$borderColor: string;
	$borderRadius: string;
	color: string;
	disabled: boolean;
}>`
	display: flex;
	pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
	border: 1px solid ${(props) => props.$borderColor};
	color: ${(props) => props.color};
	border-radius: ${(props) => props.$borderRadius};
	align-items: center;
	background: ${(props) => props.$backgroundColor};
	justify-content: space-between;
	cursor: pointer;
	padding: 0 8px;
	height: var(--height);
	gap: 8px;
	width: 100%;
`;

const ChevronIcon = styled(Chevron)<{ open: boolean }>`
	transform: rotate(${(props) => (props.open ? "0deg" : "-90deg")});
	transition: transform 0.5s;
`;

const CloseIcon = styled(Close)<{ disabled: boolean }>`
	pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const ValueContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	width: 0;
	flex: 1;
`;

const Wrapper = styled.div<{ $flex: boolean }>`
	flex: 1;
	display: ${(props) => (props.$flex ? "flex" : "unset")};
	justify-content: space-between;
	align-items: center;
	gap: 16px;
`;

const SearchInputWrapper = styled.div`
	padding: 8px;
`;

const EmptyState = styled.div<{ $isLightTheme: boolean }>`
	padding: 0 8px;
	height: var(--height);
	align-items: center;
	display: flex;
	gap: 4px;
	color: ${(props) => (props.$isLightTheme ? "var(--night-50)" : "var(--white-80)")};
`;

const StyledLoader = styled(Loader)`
	width: 20px;
	height: 20px;
`;

const ValueTextContainer = styled.div`
	flex: 1;
	overflow: hidden;
`;

const ValueText = styled.h4`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const PlaceholderValueText = styled(ValueText)<{ $isLightTheme: boolean }>`
	color: ${(props) => (props.$isLightTheme ? "var(--night-50)" : "unset")};
`;

const ValueSubtitle = styled.h5`
	color: var(--night-50);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const Hint = styled.p`
	color: var(--night-50);
`;

const LabelWrapper = styled.div`
	flex: 1;
`;

const SelectInput = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
	asyncFilter,
	borderRadius = "4px",
	className,
	clearable,
	control,
	ctaAction,
	disabled,
	event,
	flex,
	height = 40,
	hint,
	initialOptions = [],
	label,
	name,
	onCustomChange,
	onOpenCallback,
	placeholder,
	rules,
	searchable = false,
	shouldUnregister,
	theme
}: SelectInputProps<TFieldValues, TName>): JSX.Element => {
	const { t } = useTranslation();
	const [open, setOpen] = useState<boolean>(false);
	const [optionsList, setOptionsList] = useState<Option[]>([]);
	const [filterValue, setFilterValue] = useState<string>("");
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const [loading, setLoading] = useState(false);
	const isLightTheme = theme === "light";
	const ref = useRef(null);
	const {
		field,
		fieldState: { error }
	} = useController<TFieldValues, TName>({
		control,
		name,
		rules,
		shouldUnregister
	});
	const value = optionsList?.find((i) => i?.value === field.value);

	const hasValue = !!value?.value;

	const getBackgroundColor = (): string => {
		if (disabled && isLightTheme) return "var(--night-5)";
		return isLightTheme ? "var(--white-100)" : "var(--night-100)";
	};

	const getBorderColor = (): string => {
		if (error?.message) return "var(--gaspacho-100)";
		return isLightTheme ? "var(--night-10)" : "var(--night-100)";
	};

	const getColor = (): string => {
		if (error?.message && isLightTheme) return "var(--gaspacho-100)";
		if (disabled) return COLORS.NIGHT[50];
		return isLightTheme ? "var(--night-100)" : "var(--white-100)";
	};

	const getIconColor = (isDeleteAction: boolean): string => {
		if (isLightTheme && disabled) return COLORS.NIGHT[50];
		if ((isLightTheme && error?.message) || isDeleteAction) return COLORS.GASPACHO[100];
		return isLightTheme ? COLORS.LAKE[100] : COLORS.WHITE[100];
	};

	const onFilter = async (val: string): Promise<void> => {
		setFilterValue(val);
		if (asyncFilter && val.length > 2) {
			setLoading(true);
			const newOptions = await asyncFilter(val);
			const isIncludedInOptions = newOptions?.find((c) => c.value === value?.value);
			setOptionsList(!isIncludedInOptions && hasValue ? [...newOptions, value] : newOptions);
			setLoading(false);
		}
	};

	const filteredOptions = useMemo(() => {
		if (asyncFilter && filterValue?.length <= 2) return;
		return optionsList?.filter(
			(o) => o?.value !== field?.value && o.label.toLowerCase().includes(filterValue?.toLowerCase())
		);
	}, [field, optionsList, filterValue, asyncFilter]);

	const toggleOpen = (): void => {
		setOpen(!open);
		if (open) setFilterValue("");
		onOpenCallback && onOpenCallback(!open);
	};

	const onSelect = (val: Item): void => {
		if (event) logAnalyticEvent(event);
		onCustomChange ? onCustomChange(val) : field.onChange(val?.value ?? null);
		setOpen(false);
		setFilterValue("");
		onOpenCallback && onOpenCallback(false);
	};

	const onClickCtaAction = (): void => {
		setOpen(false);
		setFilterValue("");
		onOpenCallback && onOpenCallback(false);
		ctaAction.onClick();
	};

	useEffect(() => {
		if (initialOptions?.length > 0) setOptionsList(initialOptions);
	}, [initialOptions]);

	useEffect(() => {
		const handleClickOutside = (e: MouseEvent): void => {
			if (ref.current && !ref.current.contains(e.target)) {
				setOpen(false);
				setFilterValue("");
				onOpenCallback && onOpenCallback(false);
			}
		};
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [onOpenCallback]);

	return (
		<Wrapper $flex={flex} className={className} ref={ref}>
			{label && (
				<LabelWrapper>
					<Label $error={!!error?.message} htmlFor={name}>
						{label}
					</Label>
					{hint && <Hint>{hint}</Hint>}
				</LabelWrapper>
			)}
			<DropDownContainer height={height}>
				<DropDownHeader
					$backgroundColor={getBackgroundColor()}
					$borderColor={getBorderColor()}
					$borderRadius={borderRadius}
					color={getColor()}
					disabled={disabled || (clearable && hasValue)}
					onClick={toggleOpen}
				>
					<ValueContainer>
						{hasValue && value?.Icon}
						<ValueTextContainer>
							{hasValue ? (
								<ValueText>{value?.label}</ValueText>
							) : (
								<PlaceholderValueText $isLightTheme={isLightTheme}>{placeholder}</PlaceholderValueText>
							)}
							{value?.subtitle && <ValueSubtitle>{value?.subtitle}</ValueSubtitle>}
						</ValueTextContainer>
					</ValueContainer>
					{clearable && hasValue ? (
						<CloseIcon
							disabled={disabled}
							fill={getIconColor(true)}
							height={16}
							onClick={(e) => {
								e.stopPropagation();
								onSelect(null);
							}}
							width={16}
						/>
					) : (
						<ChevronIcon fill={getIconColor(false)} height={16} open={open} width={16} />
					)}
				</DropDownHeader>
				{open && (
					<DropDownListContainer $backgroundColor={getBackgroundColor()} $borderColor={getBorderColor()}>
						{searchable && (
							<SearchInputWrapper>
								<SearchInput name="search-term" onCustomChange={onFilter} theme={theme} />
							</SearchInputWrapper>
						)}
						<DropdownList>
							{loading ? (
								<EmptyState $isLightTheme={isLightTheme}>
									<StyledLoader />
									<h5>{t("components.selectInput.loadingMessage")}</h5>
								</EmptyState>
							) : (
								<>
									{filteredOptions?.length > 0 &&
										filteredOptions.map((option) => {
											const ItemIcon = option.Icon;
											return (
												<ListItem
													$isLightTheme={isLightTheme}
													color={getColor()}
													key={option.value}
													onClick={() => onSelect(option)}
												>
													{ItemIcon}
													<ValueTextContainer>
														<ValueText>{option?.label}</ValueText>
														{option?.subtitle && (
															<ValueSubtitle>{option?.subtitle}</ValueSubtitle>
														)}
													</ValueTextContainer>
												</ListItem>
											);
										})}
									{!(filteredOptions?.length > 0) && (
										<EmptyState $isLightTheme={isLightTheme}>
											<h5>
												{asyncFilter && filterValue?.length <= 2
													? t("components.selectInput.noEnoughCharacters")
													: t("components.selectInput.noResultsMessage")}
											</h5>
										</EmptyState>
									)}
								</>
							)}
							{ctaAction && (
								<ListItem $isLightTheme={isLightTheme} color={getColor()} onClick={onClickCtaAction}>
									<Plus
										fill={isLightTheme ? COLORS.NIGHT[100] : COLORS.WHITE[100]}
										height={20}
										width={20}
									/>
									<h4>{ctaAction.label}</h4>
								</ListItem>
							)}
						</DropdownList>
					</DropDownListContainer>
				)}
				{error?.message && <Error>{error.message}</Error>}
			</DropDownContainer>
		</Wrapper>
	);
};

export default SelectInput;
