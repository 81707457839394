import { useSignup } from "@hygo/shared/feature-authentication";
import { Country, Language } from "@hygo/shared/models";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SignupScreenProps } from "./screen.types";
import SignupScreen from "./SignupScreen";

const SignupContainer = (): JSX.Element => {
	const { i18n } = useTranslation();
	const {
		activeStep,
		businessSectors,
		coops,
		createNewUser,
		goNextStep,
		goPreviousStep,
		isButtonDisabled,
		loading,
		methods,
		onChangeCountry,
		onEditPhoneCountrySelector,
		passwordShown,
		PasswordVisibilityIcon,
		rules,
		togglePasswordVisibility
	} = useSignup({ defaultCountry: Country.FR, language: i18n.resolvedLanguage as Language });
	const navigate = useNavigate();
	const onGoBack: SignupScreenProps["onGoBack"] = () => navigate(-1);

	return (
		<SignupScreen
			activeStep={activeStep}
			businessSectors={businessSectors}
			coops={coops}
			goNextStep={goNextStep}
			goPreviousStep={goPreviousStep}
			isButtonDisabled={isButtonDisabled}
			loading={loading}
			methods={methods}
			onChangeCountry={onChangeCountry}
			onClickCreateAccount={createNewUser}
			onEditPhoneCountrySelector={onEditPhoneCountrySelector}
			onGoBack={onGoBack}
			passwordShown={passwordShown}
			PasswordVisibilityIcon={PasswordVisibilityIcon}
			rules={rules}
			togglePasswordVisibility={togglePasswordVisibility}
		/>
	);
};

export default SignupContainer;
