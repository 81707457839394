import { Nozzle as NozzleIcon, Sprayer as SprayerIcon } from "@hygo/shared/icons";
import { Nozzle, ProductUnit, Sprayer } from "@hygo/shared/models";
import { COLORS, NOZZLE_COLORS } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import InputTip from "../../InputTip";

interface EquipmentSectionProps {
	debit: number;
	isPelletMixture: boolean;
	nozzle: Nozzle;
	sprayer: Sprayer;
}

const SprayerTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const Debit = styled.h4`
	color: var(--lake-100);
`;

const NozzleCircle = styled.div`
	width: 48px;
	height: 48px;
	border: 2px solid var(--grass-100);
	border-radius: 50%;
	background-color: var(--white-100);
	display: flex;
	align-items: center;
	justify-content: center;
`;

const NozzleWrapper = styled.div`
	background-color: var(--night-5);
	padding: 8px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const DebitWrapper = styled(NozzleWrapper)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const StyledEquipmentSection = styled.section`
	background-color: var(--white-100);
	border-radius: 4px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	flex: 1;
`;

const EquipmentSection = ({ debit, isPelletMixture, nozzle, sprayer }: EquipmentSectionProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledEquipmentSection>
			<SprayerTitle>
				<SprayerIcon fill={COLORS.NIGHT[100]} height={20} width={20} />
				{!isPelletMixture ? <h4>{sprayer?.name}</h4> : <h4>{t("glossary.pellet")}</h4>}
			</SprayerTitle>
			{isPelletMixture ? (
				<InputTip palette={COLORS.GRASS}>
					<h5>{t("components.pelletReportCard.label")}</h5>
				</InputTip>
			) : (
				<>
					<NozzleWrapper>
						<div>
							<h4>{t("glossary.usedNozzle")}</h4>
							<h5>{nozzle?.name}</h5>
						</div>
						<NozzleCircle>
							<NozzleIcon
								fill={nozzle?.color ? NOZZLE_COLORS[nozzle.color] : COLORS.WHITE[100]}
								height={35}
								width={25}
							/>
						</NozzleCircle>
					</NozzleWrapper>
					<DebitWrapper>
						<h4>{t("equipment.pressure")}</h4>
						<Debit>
							{debit} {ProductUnit.LITER_PER_HA}
						</Debit>
					</DebitWrapper>
				</>
			)}
		</StyledEquipmentSection>
	);
};

export default EquipmentSection;
