import { Crop, DenormalizedField, User } from "@hygo/shared/models";
import { COLORS, computeColorFromConditions, hexToRGBA } from "@hygo/shared/utils";
import { fitMapBounds } from "@hygo/web/utils";
import { GoogleMap, Libraries, LoadScriptNext, Polygon } from "@react-google-maps/api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Error from "../../Error";
import Loader from "../../Loader";

interface MapSectionProps {
	crops: Crop[];
	fields: DenormalizedField[];
	user: User;
}

const mapLibs: Libraries = ["drawing", "places"];

const options = {
	clickableIcons: false,
	fullscreenControl: false,
	mapTypeControl: false,
	mapTypeId: "hybrid",
	streetViewControl: false,
	zoomControl: false
};

const Wrapper = styled.section`
	height: 100%;
`;

const containerStyle = {
	borderRadius: "4px",
	height: "100%"
};

const MapSection = ({ crops, fields, user }: MapSectionProps): JSX.Element => {
	const { i18n } = useTranslation();

	const [error, setError] = useState<boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(false);

	const onLoad = (map: google.maps.Map): void => {
		fitMapBounds({ fields, map, userLocation: user?.location });
	};

	if (error) {
		return <Error type={500} />;
	}

	return (
		<Wrapper>
			<LoadScriptNext
				googleMapsApiKey={`${process.env.NX_PUBLIC_GOOGLE_MAPS_API}&loading=async`}
				key={`map-${i18n.resolvedLanguage}`}
				language={i18n.resolvedLanguage}
				libraries={mapLibs}
				onError={() => setError(true)}
				onLoad={() => setLoaded(true)}
			>
				{loaded ? (
					<GoogleMap mapContainerStyle={containerStyle} onLoad={onLoad} options={options}>
						{fields?.map((f) => {
							const crop = crops?.find((c) => c.id === f.cropId);
							const fillColor = computeColorFromConditions(
								f?.condition,
								"FIELD",
								hexToRGBA(crop.color, 0.5, COLORS.WHITE[50])
							);
							const strokeColor = computeColorFromConditions(
								f?.condition,
								"SLOT",
								hexToRGBA(crop.color, 1, COLORS.WHITE[100])
							);

							return (
								<Polygon
									key={f.id}
									options={{
										fillColor,
										strokeColor,
										strokeWeight: 5
									}}
									path={f?.coordinates?.map((c) => ({ lat: c.lat, lng: c.lon }))}
								/>
							);
						})}
					</GoogleMap>
				) : (
					<Loader />
				)}
			</LoadScriptNext>
		</Wrapper>
	);
};
export default MapSection;
