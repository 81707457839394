import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { Checkmark } from "@hygo/shared/icons";
import { Field, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { COLORS } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import { DashboardMode } from "@hygo/web/models";
import { Button, ManagerEmptyState } from "@hygo/web/ui-components";
import * as turf from "@turf/turf";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

const Ctas = styled.div`
	padding: 16px;
	background-color: var(--white-100);
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const SubWrapper = styled.div`
	display: flex;
	flex: 1;
	padding: 16px;
	overflow: hidden;
`;

const UpdateFieldCard = (): JSX.Element => {
	const { patchFields } = useApi();
	const { t } = useTranslation();
	const {
		editedFieldId,
		fieldCoordinates,
		handleFieldSelection,
		selectedFields: initialSelectedFields,
		setCurrentMode,
		setEditedFieldId,
		setFieldCoordinates
	} = useContext(CropsScreenContext);
	const line = fieldCoordinates?.length >= 4 && turf.lineString(fieldCoordinates?.map((c) => [c.lon, c.lat]));
	const isValid = turf.booleanValid(line);
	const { showSnackbar } = useContext(SnackbarContext);
	const [loading, setLoading] = useState<boolean>(false);
	const { activeFields, defaultFarm, loadFields } = useContext(UserContext);

	const onUpdateField = async (): Promise<void> => {
		try {
			setLoading(true);
			const data = await patchFields({
				farmId: defaultFarm.id,
				fields: [
					{
						coordinates: fieldCoordinates,
						id: editedFieldId
					}
				]
			});
			const fetchedFields = await loadFields(false, defaultFarm?.id);
			setLoading(false);
			goBackToFieldsList({
				fields: fetchedFields,
				selectedFields: fetchedFields?.filter(
					(selectedField) => selectedField.id === (data?.createdFieldIds?.[0] || data?.updatedFieldIds?.[0])
				)
			});
			showSnackbar(t("snackbar.updateField.success"), SnackbarType.SUCCESS);
		} catch (e) {
			showSnackbar(t("snackbar.updateField.error"), SnackbarType.ERROR);
			throw e;
		}
	};

	const goBackToFieldsList = ({ fields, selectedFields }: { fields: Field[]; selectedFields: Field[] }): void => {
		setEditedFieldId(null);
		setFieldCoordinates([]);
		handleFieldSelection({
			centerMap: true,
			field: selectedFields[0],
			overrideActiveFields: fields,
			overrideNewSelectedFields: selectedFields,
			selection: true
		});
		setCurrentMode(DashboardMode.FIELD_LIST);
	};

	return (
		<Wrapper>
			<SubWrapper>
				<ManagerEmptyState
					asset={
						<img
							alt={t("screens.crops.updateFieldCard.emptyState.title")}
							src="/assets/images/crops/field-creation.png"
							width={320}
						/>
					}
					subtitle={t("screens.crops.updateFieldCard.emptyState.description")}
					title={t("screens.crops.updateFieldCard.emptyState.title")}
				/>
			</SubWrapper>
			<Ctas>
				<Button
					color={COLORS.SMOKE}
					inverted
					onClick={() => goBackToFieldsList({ fields: activeFields, selectedFields: initialSelectedFields })}
					text={t("button.cancel")}
				/>
				<Button
					color={COLORS.LAKE}
					disabled={!isValid}
					fillIcon
					Icon={Checkmark}
					loading={loading}
					onClick={onUpdateField}
					text={t("button.save")}
				/>
			</Ctas>
		</Wrapper>
	);
};

export default UpdateFieldCard;
