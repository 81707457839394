import { Trash } from "@hygo/shared/icons";
import {
	Crop,
	DenormalizedField,
	DenormalizedProduct,
	Feature,
	Nozzle,
	Sprayer,
	Tank,
	Target,
	User
} from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { useFeature } from "flagged";
import styled from "styled-components";

import EquipmentSection from "./EquipmentSection";
import FieldsSection from "./FieldsSection";
import MapSection from "./MapSection";
import ModulationSection from "./ModulationSection";
import NotesSection from "./NotesSection";
import ProductsSection from "./ProductsSection";
import Skeleton from "./Skeleton";
import TargetSection from "./TargetSection";

interface AccordionDetailsProps {
	crops: Crop[];
	debit: number;
	fields: DenormalizedField[];
	id: number;
	loading: boolean;
	modulation: number;
	notes: string;
	nozzle: Nozzle;
	onDeleteTask: (taskId: number) => void;
	products: DenormalizedProduct[];
	selectedFields: DenormalizedField[];
	sprayer: Sprayer;
	tankIndications: Tank;
	targets: Target[];
	user: User;
}

const Wrapper = styled.div`
	margin: -16px;
	padding: 16px;
	background: var(--gradient-light-grey);
	position: relative;
`;

const DeleteButton = styled.button`
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	padding: 0;
`;

const BottomSection = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`;

const SubWrapper = styled.div`
	flex: 1;
`;

const SharedSubWrapper = styled(SubWrapper)`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const LimitedSharedSubWrapper = styled(SharedSubWrapper)`
	max-width: 33%;
`;

const AccordionDetails = ({
	crops,
	debit,
	fields,
	id,
	loading,
	modulation,
	notes,
	nozzle,
	onDeleteTask,
	products,
	selectedFields,
	sprayer,
	tankIndications,
	targets,
	user
}: AccordionDetailsProps): JSX.Element => {
	const hasOptimize = useFeature(Feature.OPTIMIZE);

	const totalArea = fields?.reduce((r, f) => r + f.fieldArea, 0);

	return (
		<Wrapper>
			{loading ? (
				<Skeleton />
			) : (
				<>
					<DeleteButton onClick={() => onDeleteTask(id)} type="button">
						<Trash fill={COLORS.GASPACHO[100]} height={32} width={32} />
					</DeleteButton>
					<FieldsSection
						crops={crops}
						fields={fields}
						selectedFields={selectedFields}
						tankIndications={tankIndications}
						windSpeedUnity={user?.windSpeedUnity}
					/>

					<BottomSection>
						<SubWrapper>
							<MapSection crops={crops} fields={fields} user={user} />
						</SubWrapper>
						<LimitedSharedSubWrapper>
							<ProductsSection
								debit={debit}
								isPelletMixture={tankIndications?.configuration?.pelletMixture}
								products={products}
								totalArea={totalArea}
								waterTankBottomPercentage={user?.equipments?.waterTankBottomPercentage}
							/>
							{hasOptimize && <ModulationSection modulation={modulation} />}
						</LimitedSharedSubWrapper>
						<SharedSubWrapper>
							<EquipmentSection
								debit={debit}
								isPelletMixture={tankIndications?.configuration?.pelletMixture}
								nozzle={nozzle}
								sprayer={sprayer}
							/>
							{targets?.length > 0 && <TargetSection targets={targets} />}
							<NotesSection notes={notes} />
						</SharedSubWrapper>
					</BottomSection>
				</>
			)}
		</Wrapper>
	);
};

export default AccordionDetails;
