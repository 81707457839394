import { NoRain, Rain, Temperature, WaterFill, Wind, WindDirection } from "@hygo/shared/icons";
import { WeatherMetrics, WindSpeedUnity } from "@hygo/shared/models";
import { COLORS, convertMsToWindSpeedUnity } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface WeatherDetailsProps {
	metrics: WeatherMetrics;
	windSpeedUnity: WindSpeedUnity;
}

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const MetricWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
`;

const PrimaryText = styled.h5`
	color: var(--night-100);
`;

const SecondaryText = styled.h5`
	color: var(--night-50);
`;

const WindDirectionIcon = styled(WindDirection)<{ degrees: number }>`
	transform: rotate(${(props) => props.degrees}deg);
`;

const WeatherDetails = ({ metrics, windSpeedUnity }: WeatherDetailsProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<MetricWrapper>
				<Temperature height={32} width={32} />
				<div>
					<PrimaryText>
						{metrics?.maxtemp?.toFixed(0) ?? "..."} {t("units.degrees")}
					</PrimaryText>
					<SecondaryText>
						{metrics?.mintemp?.toFixed(0) ?? "..."} {t("units.degrees")}
					</SecondaryText>
				</div>
			</MetricWrapper>

			<MetricWrapper>
				{!metrics?.rmax && !metrics?.rmin ? (
					<NoRain fill={COLORS.NIGHT[10]} height={32} width={32} />
				) : (
					<Rain height={32} width={32} />
				)}
				<div>
					<PrimaryText>
						{metrics?.rmin?.toFixed(1) ?? "..."} {t("units.millimeters")}
					</PrimaryText>
					<SecondaryText>
						{metrics?.rmax?.toFixed(1) ?? "..."} {t("units.millimeters")}
					</SecondaryText>
				</div>
			</MetricWrapper>
			<MetricWrapper>
				{metrics?.winddir || metrics?.winddir === 0 ? (
					<WindDirectionIcon
						degrees={metrics?.winddir + 180}
						fill={COLORS.LAKE[100]}
						height={32}
						width={32}
					/>
				) : (
					<Wind fill={COLORS.LAKE[100]} height={32} width={32} />
				)}
				<div>
					<PrimaryText>
						{convertMsToWindSpeedUnity(metrics?.wind, windSpeedUnity)?.toFixed(0) ?? "..."}{" "}
						{t(`units.${windSpeedUnity}`)}
					</PrimaryText>
					<SecondaryText>
						{convertMsToWindSpeedUnity(metrics?.maxgust, windSpeedUnity)?.toFixed(0)} {t("glossary.gust")}
					</SecondaryText>
				</div>
			</MetricWrapper>
			<MetricWrapper>
				<WaterFill fill={COLORS.LAKE[100]} height={32} width={32} />
				<div>
					<PrimaryText>
						{metrics?.maxhumi?.toFixed(0) ?? "..."} {t("units.percentage")}
					</PrimaryText>
					<SecondaryText>
						{metrics?.minhumi?.toFixed(0) ?? "..."} {t("units.percentage")}
					</SecondaryText>
				</div>
			</MetricWrapper>
		</Wrapper>
	);
};

export default WeatherDetails;
