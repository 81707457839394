import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { emailRegEx } from "@hygo/shared/utils";
import { useContext, useState } from "react";
import { RegisterOptions, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePasswordShown } from "./usePasswordShown";

export interface SignInFormInputs {
	email: string;
	password: string;
}

interface useSigninResult {
	loading: boolean;
	methods: UseFormReturn<SignInFormInputs>;
	onSubmit: (data: SignInFormInputs) => Promise<void>;
	passwordShown: boolean;
	PasswordVisibilityIcon: JSX.Element;
	rules: Record<keyof SignInFormInputs, RegisterOptions>;
	togglePasswordVisibility: () => void;
}

export const useSignin = (): useSigninResult => {
	const { checkToken } = useApi();
	const { t } = useTranslation();
	const methods = useForm<SignInFormInputs>({ mode: "all", shouldFocusError: false });
	const { login, setAuthLoading } = useContext(UserContext);
	const [loading, setLoading] = useState<useSigninResult["loading"]>(false);
	const { passwordShown, PasswordVisibilityIcon, togglePasswordVisibility } = usePasswordShown({
		hasError: !!methods.formState.errors?.password
	});

	const rules: useSigninResult["rules"] = {
		email: {
			pattern: {
				message: t("inputs.email.errors.invalid"),
				value: emailRegEx
			},
			required: {
				message: t("inputs.email.errors.required"),
				value: true
			}
		},
		password: {
			required: {
				message: t("inputs.password.errors.required"),
				value: true
			}
		}
	};
	const onSubmit: useSigninResult["onSubmit"] = async ({ email, password }) => {
		setLoading(true);
		try {
			const token = await checkToken({ email: email, password: password });
			if (token) {
				setAuthLoading(true);
				await login(token);
			}
		} catch (error) {
			methods.setError("password", { message: t("screens.signIn.credentialsError"), type: "custom" });
			setLoading(false);
			if (error?.response?.status !== 401) throw error;
		} finally {
			setAuthLoading(false);
		}
	};

	return {
		loading,
		methods,
		onSubmit,
		passwordShown,
		PasswordVisibilityIcon,
		rules,
		togglePasswordVisibility
	};
};
