import { FieldsManagerEvents } from "@hygo/shared/models";
import { DashboardMode } from "@hygo/web/models";
import { FieldsListCard, Manager } from "@hygo/web/ui-components";
import styled from "styled-components";

import BulkActions from "./components/BulkActions";
import CreationModeSwitch from "./components/CreationModeSwitch";
import CropsListCard from "./components/CropsListCard";
import FieldDescriptionCard from "./components/FieldDescriptionCard";
import FieldDrawerTools from "./components/FieldDrawerTools";
import MapView from "./components/MapView";
import NewFieldCard from "./components/NewFieldCard";
import SmagTooltip from "./components/SmagTooltip";
import UpdateFieldCard from "./components/UpdateFieldCard";
import { CropsScreenProps } from "./screen.types";

const Wrapper = styled.div`
	background-color: var(--white-100);
	height: 100%;
	position: relative;
`;

const Version = styled.p<{ $showFieldManagementZone: boolean }>`
	position: absolute;
	top: 8px;
	left: ${(props) => (props.$showFieldManagementZone ? "718px" : "398px")};
	z-index: 1;
	color: var(--white-100);
`;

const MapWrapper = styled.div<{ $showFieldManagementZone: boolean }>`
	position: absolute;
	top: 0;
	left: ${(props) => (props.$showFieldManagementZone ? "710px" : "390px")};
	bottom: 0;
	right: 0;
	height: 100%;
	z-index: 0;
`;

const ManagerWrapper = styled.div`
	display: flex;
	height: 100%;
	flex-direction: row;
`;

const CropsScreen = ({
	admin,
	createFarm,
	crops,
	currentMode,
	defaultFarm,
	deleteFarm,
	farmerSelected,
	farms,
	fields,
	fieldsRef,
	goToSmagImport,
	handleFieldSelection,
	hasSmagFields,
	loadFarms,
	loading,
	loggedInSmag,
	selectedFields,
	setCurrentMode,
	updateDefaultFarm,
	updateFarm,
	version
}: CropsScreenProps): JSX.Element => {
	const adminWithoutUserSelected = admin && !farmerSelected;
	const createFieldMode = currentMode === DashboardMode.NEW_FIELD;
	const updateFieldMode = currentMode === DashboardMode.UPDATE_FIELD;
	const cropListMode = currentMode === DashboardMode.CROPS_LIST;
	const fieldsListMode = currentMode === DashboardMode.FIELD_LIST;
	const showFieldManagementZone = currentMode === DashboardMode.FIELD_LIST && selectedFields?.length === 1;

	return (
		<Wrapper>
			{version && <Version $showFieldManagementZone={showFieldManagementZone}>HYGO v.{version}</Version>}
			<MapWrapper $showFieldManagementZone={showFieldManagementZone}>
				<MapView />
				{fieldsListMode && loggedInSmag && fields?.length > 0 && !hasSmagFields && (
					<SmagTooltip onClick={goToSmagImport} />
				)}
				{fieldsListMode && selectedFields?.length > 1 && <BulkActions selectedFields={selectedFields} />}
				{(createFieldMode || updateFieldMode) && (
					<>
						<FieldDrawerTools />
						<CreationModeSwitch />
					</>
				)}
			</MapWrapper>

			<ManagerWrapper>
				<Manager
					createFarm={createFarm}
					defaultFarm={defaultFarm}
					deleteFarm={deleteFarm}
					farms={farms}
					loadFarms={loadFarms}
					showFarmSelector={fieldsListMode && !adminWithoutUserSelected && !loading}
					updateDefaultFarm={updateDefaultFarm}
					updateFarm={updateFarm}
					withCrudActions={!(selectedFields?.length > 0)}
				>
					{fieldsListMode && (
						<FieldsListCard
							adminWithoutUserSelected={adminWithoutUserSelected}
							crops={crops}
							farms={farms}
							fields={fields}
							fieldsRef={fieldsRef}
							handleFieldSelection={handleFieldSelection}
							loading={loading}
							multiSelectionEnabled
							selectedFields={selectedFields}
							selectFieldEvent={FieldsManagerEvents.selectFieldFromManager}
							setCurrentMode={setCurrentMode}
							showEmptyStates
							withFooter={true}
							withNeedCheck
						/>
					)}
					{cropListMode && <CropsListCard />}
					{createFieldMode && <NewFieldCard />}
					{updateFieldMode && <UpdateFieldCard />}
				</Manager>
				{showFieldManagementZone && <FieldDescriptionCard selectedField={selectedFields[0]} />}
			</ManagerWrapper>
		</Wrapper>
	);
};
export default CropsScreen;
