import { Crop, DenormalizedField, DenormalizedIrrigation, User } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import styled from "styled-components";

import Accordion from "../Accordion";
import { AccordionContent, AccordionTitle } from "../Accordion/Accordion";
import AccordionDetails from "./components/AccordionDetails";
import AccordionSummary from "./components/AccordionSummary";

interface IrrigationCardProps {
	crops: Crop[];
	irrigation: DenormalizedIrrigation;
	onDeleteIrrigation: (irrigationId: number) => void;
	selectedFields: DenormalizedField[];
	user: User;
}

const Wrapper = styled.div`
	margin-bottom: 16px;
`;

const StyledAccordion = styled(Accordion)`
	border: 1px solid var(--night-5);
	${AccordionContent}[aria-expanded="false"] {
		border-top: 1px solid var(--night-5);
	}
	${AccordionTitle} {
		gap: 16px;
	}
`;

const IrrigationCard = ({
	crops,
	irrigation,
	onDeleteIrrigation,
	selectedFields,
	user
}: IrrigationCardProps): JSX.Element => {
	const { endTime, id, quantity, startTime } = irrigation;

	return (
		<Wrapper>
			<StyledAccordion
				chevronColor={COLORS.LAKE[100]}
				summary={
					<AccordionSummary
						countryCode={user?.countryCode}
						crops={crops}
						endTime={endTime}
						fields={irrigation?.fields}
						selectedFields={selectedFields}
						startTime={startTime}
					/>
				}
			>
				<AccordionDetails
					crops={crops}
					endTime={endTime}
					fields={irrigation?.fields}
					id={id}
					onDeleteIrrigation={onDeleteIrrigation}
					quantity={quantity}
					selectedFields={selectedFields}
					startTime={startTime}
					user={user}
				/>
			</StyledAccordion>
		</Wrapper>
	);
};

export default IrrigationCard;
