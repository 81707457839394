import styled from "styled-components";

interface CardWithImageProps {
	asset: { Icon?: JSX.Element; image?: string };
	disabled?: boolean;
	image?: string;

	imageWidth: number;
	onClick?: () => void;
	subTitle?: string;
	title: string;
}

const Wrapper = styled.div<{ disabled: boolean }>`
	height: 265px;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
	border: 1px solid ${(props) => (props.disabled ? "var(--night-25)" : "var(--lake-100)")};
	border-radius: 8px;
	padding: 16px;
	justify-content: center;
	transition: background-color 0.1s ease-out;
	cursor: pointer;
	pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
	&:hover {
		background-color: var(--lake-25);
	}
`;

const Title = styled.h4`
	text-align: center;
	color: var(--night-100);
	margin-top: 12px;
`;

const Subtitle = styled.h5`
	text-align: center;
	color: var(--night-50);
`;

const CardWithImage = ({ asset, disabled, imageWidth, onClick, subTitle, title }: CardWithImageProps): JSX.Element => {
	return (
		<Wrapper disabled={disabled} onClick={onClick}>
			{asset.image && <img alt={title} src={asset.image} width={imageWidth} />}
			{asset.Icon && asset.Icon}
			<Title>{title}</Title>
			{subTitle && <Subtitle>{subTitle}</Subtitle>}
		</Wrapper>
	);
};

export default CardWithImage;
