import { DenormalizedProduct } from "@hygo/shared/models";

import { computeDosesSum } from "./computeDosesSum";

export const computeTank = ({
	debit,
	isPelletMixture,
	products,
	totalArea,
	waterTankBottomPercentage
}: {
	debit: number;
	isPelletMixture: boolean;
	products: DenormalizedProduct[];
	totalArea: number;
	waterTankBottomPercentage: number;
}): {
	products: ({ reducedDose: number; reducedQuantity: number } & DenormalizedProduct)[];
	volume: number;
	water: number;
} => {
	let incorporationOrder = isPelletMixture ? 0 : 1;
	const newProducts = products
		?.sort((a, b) => a.incorporationOrder - b.incorporationOrder)
		?.map((product, i, arr) => {
			const lastOrder = arr?.[i - 1]?.incorporationOrder;
			if (lastOrder !== product.incorporationOrder) incorporationOrder++;
			const dose = product.quantityPerHA || product.totalQuantity / (totalArea / 10000);
			const reducedDose = (dose * (100 - (product.activeModulation ? product.modulation || 0 : 0))) / 100;
			const reducedQuantity = reducedDose * (totalArea / 10000);
			return {
				...product,
				incorporationOrder,
				reducedDose,
				reducedQuantity
			};
		});
	const totalPhyto = computeDosesSum(
		newProducts?.map((p) => ({ ...p, quantityPerHA: null, totalQuantity: p.reducedQuantity })),
		null,
		isPelletMixture
	);
	const totalDebit = (totalArea / 10000) * (debit || 0);
	const volume = totalDebit + (totalDebit * (waterTankBottomPercentage || 0)) / 100;
	const water = volume - totalPhyto;
	return { products: newProducts, volume, water };
};
