import { backendUrl } from "@hygo/shared/constants";
import { ENV } from "@hygo/shared/models";
import axios, { AxiosInstance } from "axios";
import { createContext, useCallback, useMemo, useRef } from "react";

interface AxiosContextResult {
	apiRef: AxiosInstance;
	updateAuthToken: (value: string) => void;
	updateUuid: (value: string) => void;
}

export const AxiosContext = createContext({} as AxiosContextResult);

interface AxiosProviderProps {
	children: JSX.Element;
	env: ENV;
	userAgent?: string;
}

const AxiosProvider = ({ children, env, userAgent }: AxiosProviderProps): JSX.Element => {
	const apiRef = useRef(
		axios.create({
			baseURL: backendUrl[env],
			headers: userAgent
				? {
						"Access-Control-Allow-Origin": "*",
						"User-Agent": userAgent
					}
				: {
						"Access-Control-Allow-Origin": "*"
					},

			timeout: 30000
		})
	);
	const updateUuid: AxiosContextResult["updateUuid"] = useCallback((value: string): void => {
		apiRef.current.defaults.headers.common.uuid = value;
	}, []);

	const updateAuthToken: AxiosContextResult["updateAuthToken"] = useCallback((value: string): void => {
		apiRef.current.defaults.headers.common.Authorization = `Bearer ${value || ""}`;
	}, []);
	// apiRef.current.interceptors.response.use(undefined, (error) => {
	// 	const {
	// 		response: { config, data, status }
	// 	} = error;
	// 	console.log({
	// 		body: config?.data,
	// 		errorCode: data?.code,
	// 		errorDescription: data?.description,
	// 		method: config?.method,
	// 		status,
	// 		swaggerErrors: data?.data?.errors?.map((e: { message: string; params: string }) => ({
	// 			message: e?.message,
	// 			params: JSON.stringify(e?.params)
	// 		})),
	// 		token: config?.headers?.Authorization?.replace("Bearer ", ""),
	// 		url: config?.url
	// 	});
	// 	return Promise.reject(error);
	// });

	const value = useMemo(() => {
		return {
			apiRef: apiRef.current,
			updateAuthToken,
			updateUuid
		};
	}, [updateAuthToken, updateUuid]);

	return <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>;
};

export default AxiosProvider;
