import { ProductFamilyIcons, WaterFill } from "@hygo/shared/icons";
import { DenormalizedProduct, ProductUnit } from "@hygo/shared/models";
import { COLORS, computeTank, convertToFloat } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TooltipBubble from "../../TooltipBubble";

interface ProductsSectionProps {
	debit: number;
	isPelletMixture: boolean;
	products: DenormalizedProduct[];
	totalArea: number;
	waterTankBottomPercentage: number;
}

const Wrapper = styled.section`
	background-color: var(--white-100);
	padding: 16px;
	padding-left: 0;
	border-radius: 4px;

	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

const MiddleRightColumn = styled.h5`
	text-align: right;
	color: var(--night-50);
`;

const Total = styled(MiddleRightColumn)`
	color: var(--night-100);
`;

const ProductsWrapper = styled.div`
	flex: 1;
	margin-top: 16px;
	overflow: auto;
`;

const StyledToolTipBubble = styled(TooltipBubble)`
	top: 42px;
	z-index: 1;
	left: 9px;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 16px;
	margin-bottom: 16px;
	gap: 16px;
	position: relative;
	&:hover ${StyledToolTipBubble} {
		display: block;
	}
	&:last-child {
		margin-bottom: 0;
	}
`;

const ProductNameWrapper = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	gap: 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ProductName = styled.h5`
	color: var(--night-100);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TitleWrapper = styled.div`
	display: flex;
	padding-left: 16px;
	gap: 8px;
	align-items: center;
`;

const Icon = styled.svg`
	min-width: 20px;
`;

const DeletedText = styled.h5`
	color: var(--night-50);
`;

const BlueCircle = styled.div`
	width: 20px;
	height: 20px;
	background-color: var(--lake-10);
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	display: flex;
`;

const ProductNumberColor = styled.h5`
	color: var(--lake-100);
`;

const ProductsSection = ({
	debit,
	isPelletMixture,
	products,
	totalArea,
	waterTankBottomPercentage
}: ProductsSectionProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		products: tankProducts,
		volume,
		water
	} = computeTank({ debit, isPelletMixture, products, totalArea, waterTankBottomPercentage });

	return (
		<Wrapper>
			<TitleWrapper>
				<ProductFamilyIcons.OTHERS fill={COLORS.LAKE[100]} height={20} width={20} />
				<h4>{t("components.productsReportCard.title")}</h4>
			</TitleWrapper>
			<ProductsWrapper>
				{!isPelletMixture && (
					<Row>
						<BlueCircle>
							<ProductNumberColor>1</ProductNumberColor>
						</BlueCircle>
						<ProductNameWrapper>
							<Icon as={WaterFill} fill={COLORS.NIGHT[100]} height={20} width={20} />
							<ProductName>
								{t("components.productsReportCard.water").toUpperCase()}
								{waterTankBottomPercentage && ` (+${waterTankBottomPercentage}%)`}
							</ProductName>
						</ProductNameWrapper>
						<MiddleRightColumn>
							{Number.isNaN(water) ? "..." : (water / (totalArea / 10000)).toFixed(0)}{" "}
							{ProductUnit.LITER_PER_HA}
						</MiddleRightColumn>
						<MiddleRightColumn>
							{Number.isNaN(water) ? "..." : water.toFixed(0)} {t("units.l")}
						</MiddleRightColumn>
					</Row>
				)}
				{tankProducts
					?.sort((a, b) => a.incorporationOrder - b.incorporationOrder)
					.map((p) => {
						const totalUnit = p.unit.split("/")[0];
						const ProductIcon = ProductFamilyIcons[p.productFamily];
						return (
							<Row key={p.id}>
								<BlueCircle>
									<ProductNumberColor>{p.incorporationOrder}</ProductNumberColor>
								</BlueCircle>
								<StyledToolTipBubble text={p?.name?.toUpperCase()} />
								<ProductNameWrapper>
									<Icon as={ProductIcon} fill={COLORS.NIGHT[100]} height={20} width={20} />
									<ProductName>{p.name.toUpperCase()}</ProductName>
									{p?.deleted && <DeletedText>{t("glossary.deleted")}</DeletedText>}
								</ProductNameWrapper>

								<MiddleRightColumn>
									{Number.isNaN(p.reducedDose) || !p.reducedDose
										? "..."
										: convertToFloat(p.reducedDose?.toFixed(3))}{" "}
									{t(`units.${p.unit}`)}
								</MiddleRightColumn>
								<MiddleRightColumn>
									{Number.isNaN(p.reducedQuantity) || !p.reducedQuantity
										? "..."
										: convertToFloat(p.reducedQuantity?.toFixed(2))}{" "}
									{t(`units.${totalUnit}`)}
								</MiddleRightColumn>
							</Row>
						);
					})}
				<Row>
					<ProductNameWrapper />
					<Total>{t("glossary.total")}</Total>
					<Total>
						{volume.toFixed(0)} {isPelletMixture ? t("units.kg") : t("units.l")}
					</Total>
				</Row>
			</ProductsWrapper>
		</Wrapper>
	);
};

export default ProductsSection;
