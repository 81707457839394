import { CardWithImage, Loader } from "@hygo/web/ui-components";
import { Children } from "react";
import Markdown from "react-markdown";
import styled from "styled-components";

import { ChangelogScreenProps } from "./screen.types";

const Wrapper = styled.div`
	overflow: scroll;
	background-color: black;
	height: 100%;
	padding: 80px 100px;
	color: var(--white-100);
	@media (max-width: 740px) {
		padding: 20px 30px;
	}
`;

const ListItem = styled.li`
	font-family: "Nunito Regular";
	font-size: var(--14px);
	margin-top: 4px;
	&:first-letter {
		text-transform: capitalize;
	}
`;

const Paragraph = styled.p`
	font-family: "Nunito Regular";
	font-size: var(--14px);
	margin-top: 4px;
	&:first-letter {
		text-transform: capitalize;
	}
`;

const Title = styled.h1<{ $highlighted?: boolean }>`
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	color: ${(props) => (props.$highlighted ? "cornflowerblue" : "inherit")};
	justify-content: space-between;
	border-top: 1px solid white;
	padding-top: 10px;
	&:first-of-type {
		margin-top: 0;
		border-top: none;
		padding-top: 0;
	}
`;
const Subtitle = styled.h2`
	margin-top: 8px;
	font-size: var(--20px);
	font-family: "Nunito Bold";
`;

const Date = styled.span`
	color: var(--disabled-100);
	font-family: "Avenir Black";
	font-size: var(--24px);
`;

const Scope = styled.h4`
	display: inline-block;
	font-style: italic;
	font-size: var(--14px);
	font-weight: normal;
	font-family: "Nunito Regular";
	&:first-letter {
		text-transform: capitalize;
	}
`;

const EmptyWrapper = styled(Wrapper)`
	display: flex;
	flex-direction: row;
	padding: 80px 25%;
	gap: 16px;
	align-items: center;
	* {
		color: white;
	}
`;

const ChangelogScreen = ({ changelog, goToChangelog, path }: ChangelogScreenProps): JSX.Element => {
	if (!path)
		return (
			<EmptyWrapper>
				<CardWithImage
					asset={{ image: "/assets/images/changelog/iphone.png" }}
					imageWidth={165}
					onClick={() => goToChangelog("mobile")}
					subTitle="Nouveautés de l'application mobile"
					title="Mobile"
				/>
				<CardWithImage
					asset={{ image: "/assets/images/changelog/computer.png" }}
					imageWidth={165}
					onClick={() => goToChangelog("web")}
					subTitle="Nouveautés du Dashboard"
					title="Web"
				/>
			</EmptyWrapper>
		);
	return (
		<Wrapper>
			{changelog ? (
				<Markdown
					components={{
						h1(props) {
							const newChildren = Children.toArray(props.children)?.map((c: JSX.Element) =>
								c.type === "a" ? c.props.children : <Date key={crypto.randomUUID()}>{c}</Date>
							);

							return <Title $highlighted {...props} children={newChildren} />;
						},
						h2(props) {
							const newChildren = Children.toArray(props.children)?.map((c: JSX.Element) =>
								c.type === "a" ? c.props.children : <Date key={crypto.randomUUID()}>{c}</Date>
							);

							return <Title {...props} children={newChildren} />;
						},
						h4: Subtitle,
						li(props) {
							const newChildren = Children.toArray(props.children)
								?.filter((c: JSX.Element) => c.type !== "a")
								?.map((c: JSX.Element | string) => {
									if ((c as JSX.Element)?.type === "strong")
										return <Scope key={crypto.randomUUID()}>{c}</Scope>;
									if (typeof c === "string") return c?.replace(/["'()]/g, "");
									return c;
								});

							return <ListItem {...props} children={newChildren} />;
						},

						p(props) {
							const newChildren = Children.toArray(props.children)
								?.filter((c: JSX.Element) => c.type !== "a")
								?.map((c: JSX.Element | string) => {
									if ((c as JSX.Element)?.type === "strong")
										return <Scope key={crypto.randomUUID()}>{c}</Scope>;
									if (typeof c === "string") return c?.replace(/["'()]/g, "");
									return c;
								});

							return <Paragraph {...props} children={newChildren} />;
						}
					}}
					unwrapDisallowed
				>
					{changelog}
				</Markdown>
			) : (
				<Loader />
			)}
		</Wrapper>
	);
};

export default ChangelogScreen;
