import { Pencil, Touch } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import { CreationMode } from "@hygo/web/models";
import { Button } from "@hygo/web/ui-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	left: 0;
	z-index: 10;
	position: absolute;
	top: 25px;
	width: 100%;
	display: flex;
	pointer-events: none;
	justify-content: center;
	gap: 16px;
`;

const StyledButton = styled(Button)`
	pointer-events: auto;
`;

const CreationModeSwitch = (): JSX.Element => {
	const { t } = useTranslation();
	const { creationMode, setCreationMode, setFieldCoordinates } = useContext(CropsScreenContext);

	const toggleMode = (mode: CreationMode): void => {
		setFieldCoordinates([]);
		setCreationMode(mode);
	};

	const isRpg = creationMode === CreationMode.RPG;

	return (
		<Wrapper>
			<StyledButton
				color={isRpg ? COLORS.NIGHT : COLORS.SMOKE}
				fillIcon
				Icon={Touch}
				inverted={!isRpg}
				onClick={() => !isRpg && toggleMode(CreationMode.RPG)}
				text={t("button.select")}
				width="fit-content"
			/>
			<StyledButton
				color={isRpg ? COLORS.SMOKE : COLORS.NIGHT}
				fillIcon
				Icon={Pencil}
				inverted={isRpg}
				onClick={() => isRpg && toggleMode(CreationMode.DRAWING)}
				text={t("button.draw")}
				width="fit-content"
			/>
		</Wrapper>
	);
};
export default CreationModeSwitch;
