import styled from "styled-components";

interface FormCardProps {
	children: JSX.Element | JSX.Element[];
	className?: string;
}

const Wrapper = styled.div`
	border-radius: 16px;
	padding: 32px 64px;
	background-color: var(--white-100);
	width: 55%;
	@media (max-width: 740px) {
		width: 100%;
		height: 100%;
		border-radius: 0;
		padding: 40px 16px;
	}
`;

const FormCard = ({ children, className }: FormCardProps): JSX.Element => {
	return <Wrapper className={className}>{children}</Wrapper>;
};

export default FormCard;
