import { ENV } from "@hygo/shared/models";
import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

Sentry.init({
	beforeBreadcrumb: (breadcrumb, hint) => {
		if (breadcrumb.category !== "xhr") return breadcrumb;

		if (
			hint.xhr?.responseURL?.includes("logs") ||
			hint.xhr?.responseURL?.includes("generate_204") ||
			hint.xhr?.responseURL?.includes("symbolicate") ||
			hint.xhr?.responseURL?.includes("amplitude")
		) {
			return null;
		}
		const data =
			hint.xhr.status >= 400
				? {
						requestBody: hint.xhr.__sentry_xhr_v3__?.body,
						response: hint.xhr.response,
						responseUrl: hint.xhr.responseURL
					}
				: breadcrumb.data;
		return { ...breadcrumb, data };
	},
	beforeSend(
		event,
		hint: {
			originalException: {
				response: { data: { code: string }; request: { responseURL: string }; status: number };
			};
		}
	) {
		const status = hint.originalException?.response?.status;
		const code =
			hint.originalException?.response?.data?.code ?? (status === 502 ? "502 Bad Gateway" : "Unknown Code");
		const url = hint.originalException?.response?.request?.responseURL?.replace(/[0-9]+/g, "X");
		if (status && code && url) {
			event.fingerprint = [url, status.toString(), code];
		}
		return event;
	},
	dsn: "https://7594a658fc134c94811bbebc8722b309@o1077021.ingest.sentry.io/6274711",
	enabled: process.env.NX_PUBLIC_ENV === ENV.STAGING || process.env.NX_PUBLIC_ENV === ENV.PRODUCTION,
	environment: process.env.NX_PUBLIC_ENV,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			blockAllMedia: false,
			maskAllInputs: false,
			maskAllText: false
		})
	],
	replaysOnErrorSampleRate: 1.0,
	replaysSessionSampleRate: 0,
	tracesSampleRate: 1
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<StrictMode>
		<App />
	</StrictMode>
);
