import {
	Condition,
	DenormalizedField,
	Nozzle,
	NozzleColor,
	NozzleFamily,
	ProductFamily,
	Sprayer,
	Task
} from "@hygo/shared/models";

export const FAKE_SPRAYER: Sprayer = {
	defaultNozzleId: 0,
	deleted: false,
	gpsBarcode: null,
	id: 0,
	name: "Amazone de Julien",
	weatherBarcode: null
};

export const FAKE_TASK: Task = {
	condition: Condition.EXCELLENT,
	debit: null,
	detectedTaskId: null,
	endTime: "2053-12-11T21:00:00.000Z",
	fields: [],
	id: 1,
	metrics: null,
	mixtureId: null,
	modulation: 0,
	notes: null,
	nozzleId: null,
	productFamily: ProductFamily.OTHERS,
	productNotes: [],
	products: [],
	sprayerId: null,
	startTime: "2023-12-11T18:00:00.000Z",
	targetIds: []
};

export const FAKE_NOZZLE: Nozzle = {
	color: NozzleColor.BLUE,
	deleted: false,
	family: NozzleFamily.CALIBRATE,
	height: 5,
	id: 0,
	name: "Ma buse",
	pressure: 1,
	speed: 1,
	sprayerId: 0
};

export const FAKE_FIELDS: DenormalizedField[] = [
	{
		area: 0,
		condition: Condition.EXCELLENT,
		cropId: 162,
		id: 1,
		name: "Bas Poteau",
		svg: "M 70.33685999999983 34.32361999998079 L 70.52333999999938 30.608040000004166 70.56264999999895 27.157540000004587 70.86318999999897 24.384029999993118 72.01755999999948 21.25804999998593 74.27902999999958 18.30063999996412 76.64450999999906 15.920059999956493 11.207979999998674 0 0 17.266919999983088 70.33685999999983 34.32361999998079 Z"
	},
	{
		condition: Condition.CORRECT,
		cropId: 162,
		id: 2,
		name: "Prairies vaches",
		svg: "M 20.889609999998893 0 L 20.84631999999864 0.040890000008175775 18.604669999999768 2.1579799999926763 16.309979999999946 6.676180000013687 0 15.021689999983323 2.0780299999989538 16.845940000038695 4.53273999999837 18.62312000000088 12.560959999998733 23.32658000000265 14.76874999999822 24.219140000028005 18.292569999998065 24.96738000004939 22.994539999998675 24.736869999983924 27.144719999998568 23.94137999999657 37.493009999998165 13.007690000037542 34.97856999999938 11.965799999984483 34.516869999998704 11.757299999999304 37.5352299999987 7.993450000043367 39.452769999999134 5.39209000002927 36.00622999999858 3.8093900000291114 35.43179999999868 3.5864400000207297 31.678019999998863 1.909520000040743 29.857449999999286 3.691480000043157 29.464089999999832 3.5239400000364185 29.472659999998374 3.5151000000155364 29.332129999999346 3.482360000006679 20.889609999998893 0 Z"
	},
	{
		condition: Condition.GOOD,
		cropId: 162,
		id: 3,
		name: "L'étang bleu",
		svg: "M 8.050210000001279 15.69713999998612 L 8.900110000000794 16.18359999994823 10.699020000000115 17.4899999999667 0 23.55305999998336 2.7858999999996747 26.168799999979342 5.451450000000246 29.98638999997638 5.6048000000008535 30.1245299999664 9.642260000000125 27.82854999999529 27.686660000001417 17.37028999997392 21.83637000000127 13.016989999954376 40.13475000000044 2.435159999976122 36.11154999999977 0 8.050210000001279 15.69713999998612 Z"
	}
]?.map((f) => ({
	area: 0,
	coordinates: [],
	deleted: false,
	detected: null,
	endTime: null,
	fieldArea: 0,
	geofoliaReady: null,
	harvestDate: null,
	location: null,
	metrics: null,
	mileosStatus: null,
	needCheck: false,
	needCropCheck: false,
	smagCropZoneUid: null,
	sowingDate: null,
	startTime: null,
	town: null,
	...f
}));

export const FAKE_USAGES = [
	{
		cropId: 26,
		maxCropDose: 1,
		minCropDose: 1
	},
	{
		cropId: 52,
		maxCropDose: 1,
		minCropDose: 1
	}
];
