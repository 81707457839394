import { ProductFamilyIcons } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface ModulationSectionProps {
	modulation: number;
}

const Wrapper = styled.section`
	background-color: var(--lake-100);
	padding: 16px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	gap: 8px;
`;

const Text = styled.h4`
	color: var(--white-100);
`;

const ModulationSection = ({ modulation }: ModulationSectionProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<ProductFamilyIcons.OTHERS fill={COLORS.WHITE[100]} height={32} width={32} />
			<Text>{t("components.modulationReportCard.title", { modulation: modulation?.toFixed(0) || 0 })}</Text>
		</Wrapper>
	);
};

export default ModulationSection;
