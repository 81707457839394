import { Parcelles, SmagLogin } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { CardWithImage, EmptyState, FormCard, FormHeader, InputTip } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ImportLandingScreenProps } from "./screen.types";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background: var(--gradient-background-2);
`;

const Card = styled(FormCard)`
	box-shadow: 0px 6px 60px rgba(0, 83, 94, 0.05);
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const FlowWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 40px;
	margin: 32px 0;
`;

const ImportLandingScreen = ({
	defaultFarm,
	goToDashboard,
	goToSmag,
	goToTelepac,
	loggedInSmag
}: ImportLandingScreenProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			{defaultFarm ? (
				<Card>
					<FormHeader
						subTitle={t("screens.importLanding.subtitle")}
						title={t("screens.importLanding.title")}
					/>
					<FlowWrapper>
						<CardWithImage
							asset={{ image: "/assets/images/import/telepac.png" }}
							imageWidth={200}
							onClick={goToTelepac}
							title={t("screens.importLanding.telepac.title")}
						/>
						<CardWithImage
							asset={{
								Icon: !loggedInSmag && <SmagLogin height={160} width={200} />,
								image: loggedInSmag && "/assets/images/import/smag.png"
							}}
							imageWidth={200}
							onClick={goToSmag}
							title={
								loggedInSmag
									? t("screens.importLanding.smag.title.notLoggedIn")
									: t("screens.importLanding.smag.title.loggedIn")
							}
						/>
					</FlowWrapper>
					<InputTip palette={COLORS.DISABLED} withBorder={false}>
						<ColoredCardText>{t("screens.importLanding.explicabilityImport")}</ColoredCardText>
					</InputTip>
				</Card>
			) : (
				<EmptyState
					btnIcon={Parcelles}
					btnText={t("components.emptyState.noDefaultFarm.btn")}
					description={t("components.emptyState.noDefaultFarm.description")}
					illustration={<img alt="ferme" src={"/assets/images/coop.png"} width={100} />}
					onClick={goToDashboard}
					title={t("components.emptyState.noDefaultFarm.title")}
				/>
			)}
		</Wrapper>
	);
};

export default ImportLandingScreen;
