import { EditCrop, Parcelles, Plug, PulvEquipment, Sprayer, User } from "@hygo/shared/icons";
import { Feature, NavbarEvents } from "@hygo/shared/models";
import { Route } from "@hygo/web/models";

const routes: Route[] = [
	{
		event: NavbarEvents.goToFieldsManager,
		feature: Feature.FARM_WEATHER,
		icon: <Parcelles height={24} width={24} />,
		id: "dashboard",
		path: "/dashboard"
	},
	{
		event: NavbarEvents.goToImport,
		feature: Feature.FARM_WEATHER,
		icon: <EditCrop height={24} width={24} />,
		id: "import",
		path: "/import"
	},
	{
		admin: true,
		icon: <User height={24} width={24} />,
		id: "admin",
		path: "/admin"
	},
	{
		event: NavbarEvents.goToTasks,
		feature: Feature.TASKS,
		icon: <PulvEquipment height={24} width={24} />,
		id: "tasks",
		path: "/interventions"
	},
	{
		event: NavbarEvents.goToSprayers,
		feature: Feature.TASKS,
		icon: <Sprayer glassesColor="transparent" height={24} width={24} />,
		id: "sprayers",
		path: "/sprayers"
	},
	{
		event: NavbarEvents.goToOAD,
		feature: Feature.FARM_WEATHER,
		icon: <Plug height={24} width={24} />,
		id: "oad",
		path: "/oad"
	}
];

export default routes;
