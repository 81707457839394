import { AmplitudeContext } from "@hygo/shared/amplitude";
import { AmplitudeEvent, Crop, Field } from "@hygo/shared/models";
import { COLORS, convertToHa } from "@hygo/shared/utils";
import { MouseEvent, RefObject, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Accordion from "../../Accordion";
import AccordionSummary from "./AccordionSummary";
import FieldCard from "./FieldCard";

const Wrapper = styled.div`
	margin: 0 16px;
	margin-bottom: 8px;
`;

interface FieldsListProps {
	crop: Crop;
	crops: Crop[];
	fields: Field[];
	fieldsRef?: { [x: number]: RefObject<HTMLDivElement> };
	handleFieldSelection: (data: {
		centerMap: boolean;
		field: Field;
		overrideActiveFields: Field[];
		overrideNewSelectedFields: Field[];
		selection: boolean;
	}) => void;
	needCheck?: boolean;
	selectedFields: Field[];
	selectFieldEvent: AmplitudeEvent;
	withCheckbox: boolean;
}

const FieldsList = ({
	crop,
	crops,
	fields,
	fieldsRef,
	handleFieldSelection,
	needCheck,
	selectedFields,
	selectFieldEvent,
	withCheckbox
}: FieldsListProps): JSX.Element => {
	const { i18n } = useTranslation();
	const { formatFields, logAnalyticEvent } = useContext(AmplitudeContext);

	const collator = useMemo(
		() => new Intl.Collator(i18n.resolvedLanguage, { numeric: true, sensitivity: "base" }),
		[i18n]
	);

	const totalCropSurfaceinHa = fields.reduce((acc: number, next: Field) => acc + next.area, 0);
	const numberOfFieldsWithSameCrop = fields.length;

	const isCropCheck = fields.every((field: Field) =>
		selectedFields?.find((selected: Field) => selected.id === field.id)
	);

	const onClickCropCheckbox = (event: MouseEvent<HTMLDivElement>): void => {
		event.stopPropagation();
		handleFieldSelection({
			centerMap: true,
			field: null,
			overrideActiveFields: null,
			overrideNewSelectedFields: isCropCheck
				? selectedFields?.filter((el) => {
						const isSelectedField = fields.find((e) => e.id === el.id);
						return !isSelectedField;
					})
				: [...selectedFields, ...fields],
			selection: true
		});
	};

	const isIndeterminate = fields.some((field: Field) =>
		selectedFields?.find((selected: Field) => selected.id === field.id)
	);

	const isSelectedFields = (field: Field): boolean => {
		return Boolean(selectedFields?.find((selected) => selected.id === field.id));
	};
	const onClick = (event: MouseEvent<HTMLDivElement>, field: Field, selection: boolean): void => {
		event.stopPropagation();
		logAnalyticEvent(selectFieldEvent, {
			field: formatFields([field], crops),
			type: isSelectedFields(field) ? "Retrait" : "Ajout"
		});
		handleFieldSelection({
			centerMap: true,
			field,
			overrideActiveFields: null,
			overrideNewSelectedFields: null,
			selection
		});
	};

	return (
		<Wrapper>
			<Accordion
				chevronColor={needCheck ? COLORS.GASPACHO[100] : COLORS.NIGHT[100]}
				needCheck={needCheck}
				opened={!!selectedFields?.find((f) => f.cropId === crop.id)}
				summary={
					<AccordionSummary
						area={convertToHa(totalCropSurfaceinHa)}
						checked={isCropCheck}
						crop={crop}
						isIndeterminate={isIndeterminate}
						needCheck={needCheck}
						numberOfFields={numberOfFieldsWithSameCrop}
						onClickCheckbox={onClickCropCheckbox}
						withCheckbox={withCheckbox}
					/>
				}
			>
				{fields
					.sort((a, b) => collator.compare(a.name, b.name))
					.map((field: Field) => {
						return (
							<FieldCard
								crop={crop}
								field={field}
								fieldRef={fieldsRef?.[field.id]}
								isSelectedField={isSelectedFields(field)}
								key={field.id}
								onClick={onClick}
								withCheckbox={withCheckbox}
							/>
						);
					})}
			</Accordion>
		</Wrapper>
	);
};

export default FieldsList;
