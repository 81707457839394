const DEFAULT = { lat: 47.17544, lon: 2.22327 };

export const fitMapBounds = ({
	fields,
	map,
	userLocation
}: {
	fields: { coordinates: { lat: number; lon: number }[] }[];
	map: google.maps.Map;
	userLocation: { lat: number; lon: number };
}): void => {
	const coordinates = fields?.flatMap((f) => f.coordinates)?.filter((f) => f);
	if (!coordinates?.length) {
		const bounds = new window.google.maps.LatLngBounds();
		const defaultBounds = userLocation
			? [
					{ lat: userLocation.lat - 0.01, lng: userLocation.lon - 0.01 },
					{ lat: userLocation.lat + 0.01, lng: userLocation.lon + 0.01 }
				]
			: [
					{ lat: DEFAULT.lat - 3, lng: DEFAULT.lon - 3 },
					{ lat: DEFAULT.lat + 3, lng: DEFAULT.lon + 3 }
				];

		bounds.extend(defaultBounds[0]).extend(defaultBounds[1]);
		map.fitBounds(bounds);
		return null;
	}

	const east = Math.max(...coordinates.map((c) => c?.lon));
	const west = Math.min(...coordinates.map((c) => c?.lon));
	const north = Math.max(...coordinates.map((c) => c?.lat));
	const south = Math.min(...coordinates.map((c) => c?.lat));
	const bounds = new window.google.maps.LatLngBounds();
	bounds.extend({ lat: north, lng: east }).extend({ lat: south, lng: west });
	map.fitBounds(bounds);
};
