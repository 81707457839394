import { UserContext } from "@hygo/shared/contexts";
import { Close, Refresh, Trash, Undo } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import { CreationMode, DashboardMode } from "@hygo/web/models";
import { Button } from "@hygo/web/ui-components";
import { fitMapBounds } from "@hygo/web/utils";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	left: 0;
	z-index: 10;
	position: absolute;
	bottom: 25px;
	pointer-events: none;
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 16px;
`;

const StyledButton = styled(Button)`
	pointer-events: auto;
`;

const FieldDrawerTools = (): JSX.Element => {
	const { t } = useTranslation();
	const { creationMode, currentMode, fieldCoordinates, map, RPGFields, selectedFields, setFieldCoordinates } =
		useContext(CropsScreenContext);
	const { activeFields, user } = useContext(UserContext);
	const isRPG =
		(currentMode === DashboardMode.NEW_FIELD || currentMode === DashboardMode.UPDATE_FIELD) &&
		creationMode === CreationMode.RPG;

	const onCancelDrawing = (): void => setFieldCoordinates((prev) => prev.slice(0, -1));

	const onRemoveDrawing = (): void => {
		fitMapBounds({ fields: isRPG ? RPGFields : activeFields, map, userLocation: user?.location });
		setFieldCoordinates([]);
	};

	const onResetDrawing = (): void => {
		fitMapBounds({ fields: selectedFields, map, userLocation: user?.location });
		setFieldCoordinates(selectedFields[0]?.coordinates);
	};

	return (
		<Wrapper>
			{fieldCoordinates?.length > 1 && (
				<StyledButton
					color={COLORS.SMOKE}
					fillIcon
					Icon={Undo}
					inverted
					onClick={onCancelDrawing}
					text={t("screens.crops.fieldDrawerTools.cancelDot")}
					width="fit-content"
				/>
			)}
			{creationMode === CreationMode.DRAWING &&
				currentMode === DashboardMode.UPDATE_FIELD &&
				selectedFields?.[0]?.coordinates?.length > 0 && (
					<StyledButton
						color={COLORS.SMOKE}
						fillIcon
						Icon={Refresh}
						inverted
						onClick={onResetDrawing}
						text={t("screens.crops.fieldDrawerTools.resetShape")}
						width="fit-content"
					/>
				)}
			{fieldCoordinates?.length > 0 && (
				<StyledButton
					color={COLORS.GASPACHO}
					fillIcon
					Icon={isRPG ? Close : Trash}
					onClick={onRemoveDrawing}
					text={isRPG ? t("button.unselect") : t("button.delete")}
					width="fit-content"
				/>
			)}
		</Wrapper>
	);
};
export default FieldDrawerTools;
