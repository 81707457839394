import { init as amplitudeInit, identify, Identify, setUserId, track } from "@amplitude/analytics-browser";
import { AmplitudeProvider } from "@hygo/shared/amplitude";
import { AxiosProvider } from "@hygo/shared/api";
import { UserProvider } from "@hygo/shared/contexts";
import { initTranslations } from "@hygo/shared/i18n";
import { ModalsProvider, ModalsRoot } from "@hygo/shared/modals";
import { AmplitudeUser, ENV } from "@hygo/shared/models";
import { SnackbarProvider } from "@hygo/shared/snackbar";
import { AuthProvider, OADProvider } from "@hygo/web/contexts";
import { Error as ErrorComponent, GlobalStyles, Loader, Snackbar, Typography } from "@hygo/web/ui-components";
import * as Sentry from "@sentry/react";
import LanguageDetector from "i18next-browser-languagedetector";
import { useCallback, useEffect, useState } from "react";
import { IntercomProvider } from "react-use-intercom";

import { AppRouter } from "./router/AppRouter";

const App = (): JSX.Element => {
	const [i18nReady, setI18nReady] = useState<boolean>(false);

	const deleteAuthCookie = useCallback(async (): Promise<void> => {
		await localStorage.removeItem("hygoCookie");
	}, []);

	const storeAuthCookie = useCallback(async (token: string): Promise<void> => {
		await localStorage.setItem("hygoCookie", token);
	}, []);

	const captureSentryException = useCallback(async (e: Error): Promise<void> => {
		Sentry.captureException(e);
	}, []);

	useEffect(() => {
		const init = async (): Promise<void> => {
			const simpleLocalizeEnvironment = process.env.NX_PUBLIC_ENV === ENV.PRODUCTION ? "production" : "latest";
			amplitudeInit(process.env.NX_PUBLIC_AMPLITUDE_API_KEY, null, { defaultTracking: true, minIdLength: 1 });

			await initTranslations({
				callback: () => setI18nReady(true),
				callbackError: (e) => {
					throw e;
				},
				escapeValue: false,
				languageDetector: LanguageDetector,
				loadPath: `https://cdn.simplelocalize.io/aae831913f884e998402e80a2a2c5eae/_${simpleLocalizeEnvironment}/{{lng}}/{{ns}}`,
				namespace: "web"
			});
		};

		init();
	}, []);
	const setUserCallback = useCallback((amplitudeUser: AmplitudeUser): void => {
		const identifyObj = new Identify();
		Object.keys(amplitudeUser).forEach((key: keyof AmplitudeUser) => identifyObj.set(key, amplitudeUser[key]));
		identify(identifyObj);
	}, []);

	return (
		<>
			<GlobalStyles />
			<Typography />
			<Sentry.ErrorBoundary fallback={<ErrorComponent type={500} />}>
				{!i18nReady ? (
					<Loader />
				) : (
					<AxiosProvider env={process.env.NX_PUBLIC_ENV as ENV}>
						<IntercomProvider
							appId={process.env.NX_PUBLIC_ENV === ENV.PRODUCTION ? "z1r6toj5" : "xj4wgkx7"}
						>
							<AmplitudeProvider
								amplitudeLogCallback={track}
								setUserCallback={setUserCallback}
								setUserIdCallback={setUserId}
							>
								<SnackbarProvider Component={Snackbar} hideManually>
									<UserProvider
										captureSentryException={captureSentryException}
										deleteAuthCookie={deleteAuthCookie}
										isMobile={false}
										storeAuthCookie={storeAuthCookie}
										versioning={{
											version:
												process.env.NX_PUBLIC_WEB_RELEASE_VERSION ||
												process.env.NX_PUBLIC_WEB_LAST_RELEASE_VERSION
										}}
									>
										<AuthProvider>
											<OADProvider>
												<ModalsProvider>
													<AppRouter
														version={
															process.env.NX_PUBLIC_WEB_RELEASE_VERSION ||
															process.env.NX_PUBLIC_WEB_LAST_RELEASE_VERSION
														}
													/>
													<ModalsRoot />
												</ModalsProvider>
											</OADProvider>
										</AuthProvider>
									</UserProvider>
								</SnackbarProvider>
							</AmplitudeProvider>
						</IntercomProvider>
					</AxiosProvider>
				)}
			</Sentry.ErrorBoundary>
		</>
	);
};

export default App;
