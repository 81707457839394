import { Aim } from "@hygo/shared/icons";
import { Target } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TargetIcon from "../../TargetIcon";

interface TargetSectionProps {
	targets: Target[];
}

const Wrapper = styled.section`
	background-color: var(--white-100);
	border-radius: 4px;
	padding: 16px;
	flex: 1;
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const TargetsWrapper = styled.div`
	margin-top: 16px;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 8px;
	&:last-child {
		margin-bottom: 0;
	}
`;

const TargetSection = ({ targets }: TargetSectionProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<TitleWrapper>
				<Aim fill={COLORS.LAKE[100]} height={20} width={20} />
				<h4>{t("components.targetsReportCard.title")}</h4>
			</TitleWrapper>
			<TargetsWrapper>
				{targets.map((target) => {
					return (
						<Row key={target.id}>
							<TargetIcon fill={COLORS.NIGHT[100]} height={20} target={target} width={20} />
							<h5>{target.name}</h5>
						</Row>
					);
				})}
			</TargetsWrapper>
		</Wrapper>
	);
};

export default TargetSection;
