import { Pencil } from "@hygo/shared/icons";
import { COLORS } from "@hygo/shared/utils";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TextInput from "../../TextInput";

interface NotesSectionProps {
	notes: string;
}

const Wrapper = styled.section`
	background-color: var(--white-100);
	border-radius: 4px;
	padding: 16px;
	flex: 1;
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const Notes = styled.div`
	margin-top: 16px;
`;

const NotesSection = ({ notes }: NotesSectionProps): JSX.Element => {
	const { t } = useTranslation();
	const methods = useForm({
		defaultValues: {
			notes
		}
	});
	return (
		<Wrapper>
			<TitleWrapper>
				<Pencil fill={COLORS.LAKE[100]} height={20} width={20} />
				<h4>{t("components.notesReportCard.title")}</h4>
			</TitleWrapper>

			<FormProvider {...methods}>
				<Notes>
					<TextInput disabled name="notes" placeholder={t("components.notesReportCard.empty")} />
				</Notes>
			</FormProvider>
		</Wrapper>
	);
};

export default NotesSection;
