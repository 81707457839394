import { availableCountries, countryMapping } from "@hygo/shared/constants";
import { ArrowLeft } from "@hygo/shared/icons";
import { Country } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import {
	Button,
	ContainerWithBgImage,
	FormCard,
	FormHeader,
	GooglePlacesAutocomplete,
	InputTip,
	PasswordStrength,
	PhoneNumberInput,
	SelectInput,
	Stepper,
	TextInput
} from "@hygo/web/ui-components";
import { FormProvider } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { SignupScreenProps } from "./screen.types";

const Form = styled.form`
	margin-top: 32px;
`;

const StyledButton = styled(Button)`
	margin-top: 32px;
`;

const InputWrapper = styled.div`
	display: flex;
	margin-bottom: 16px;
	flex-direction: column;
	gap: 8px;
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const CGU = styled.p`
	text-align: center;
	margin-top: 32px;
`;

const CGULink = styled.a`
	text-decoration: underline;
	font-weight: 700;
	color: var(--night-100);
`;

const SignupScreen = ({
	activeStep,
	businessSectors,
	coops,
	goNextStep,
	goPreviousStep,
	isButtonDisabled,
	loading,
	methods,
	onChangeCountry,
	onClickCreateAccount,
	onEditPhoneCountrySelector,
	onGoBack,
	passwordShown,
	PasswordVisibilityIcon,
	rules,
	togglePasswordVisibility
}: SignupScreenProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<ContainerWithBgImage backgroundImage={"/assets/images/bg.jpg"}>
			<FormCard>
				<Stepper activeStep={activeStep} numberOfSteps={3} />
				<FormHeader
					backIcon={<ArrowLeft />}
					onGoBack={activeStep === 1 ? onGoBack : goPreviousStep}
					title={t("screens.signUpAgri.title")}
				/>

				<FormProvider {...methods}>
					<Form onSubmit={methods.handleSubmit(onClickCreateAccount)}>
						{activeStep === 1 && (
							<>
								<InputWrapper>
									<SelectInput
										control={methods.control}
										disabled={loading}
										initialOptions={availableCountries.map((c) => {
											return {
												Icon: countryMapping[c].flag({
													height: 20,
													width: 20
												}) as JSX.Element,
												label: t(`country.${c}`),
												value: c
											};
										})}
										label={t("inputs.country.label")}
										name="countryCode"
										onCustomChange={(d) => onChangeCountry(d.value as Country)}
										placeholder={t("inputs.country.placeholder")}
										rules={rules.countryCode}
										theme="light"
									/>
								</InputWrapper>
								{methods.watch().countryCode && (
									<InputWrapper>
										<GooglePlacesAutocomplete
											countryCode={methods.watch().countryCode}
											label={t("inputs.location.label")}
											name="googleAutocomplete"
											onChange={(v) => methods.setValue("location", v)}
											placeholder={t("inputs.location.placeholder")}
											rules={rules.googleAutocomplete}
										/>
										<InputTip palette={COLORS.DISABLED} withBorder={false}>
											<ColoredCardText>{t("inputs.location.hint")}</ColoredCardText>
										</InputTip>
									</InputWrapper>
								)}

								<InputWrapper>
									<SelectInput
										control={methods.control}
										disabled={loading}
										initialOptions={businessSectors.map((c) => {
											return {
												label: t(`businessSectors.${c}`),
												value: c
											};
										})}
										label={t("inputs.businessSector.label")}
										name="businessSector"
										placeholder={t("inputs.businessSector.placeholder")}
										rules={rules.businessSector}
										theme="light"
									/>
									<InputTip palette={COLORS.DISABLED} withBorder={false}>
										<ColoredCardText>{t("inputs.businessSector.inputTip")}</ColoredCardText>
									</InputTip>
								</InputWrapper>

								<StyledButton
									color={COLORS.LAKE}
									disabled={isButtonDisabled}
									onClick={goNextStep}
									text={t("button.next")}
								/>
							</>
						)}
						{activeStep === 2 && (
							<>
								<InputWrapper>
									<TextInput
										autoComplete="email"
										label={t("inputs.email.label")}
										name="email"
										placeholder={t("inputs.email.placeholder")}
										rules={rules.email}
									/>
								</InputWrapper>
								<InputWrapper>
									<PhoneNumberInput
										control={methods.control}
										error={methods.formState.errors?.principalPhone}
										onChange={onEditPhoneCountrySelector}
										phoneCountrySelector={methods.watch().phoneCountrySelector}
									/>
								</InputWrapper>
								<InputWrapper>
									<TextInput
										autoComplete="new-password"
										label={t("inputs.password.label")}
										name="password"
										placeholder="********"
										placeholderIcon={{
											icon: (
												<div onClick={togglePasswordVisibility}>{PasswordVisibilityIcon}</div>
											),
											position: "right"
										}}
										rules={rules.password}
										type={passwordShown ? "text" : "password"}
									/>
								</InputWrapper>
								<PasswordStrength password={methods.watch().password} />
								<StyledButton
									color={COLORS.LAKE}
									disabled={isButtonDisabled}
									onClick={goNextStep}
									text={t("button.next")}
								/>
							</>
						)}
						{activeStep === 3 && (
							<>
								<InputWrapper>
									<TextInput
										autoCapitalize="words"
										autoComplete="given-name"
										label={t("inputs.firstName.label")}
										name="firstName"
										placeholder={t("inputs.firstName.placeholder")}
										rules={rules.firstName}
									/>
								</InputWrapper>
								<InputWrapper>
									<TextInput
										autoCapitalize="words"
										autoComplete="family-name"
										label={t("inputs.lastName.label")}
										name="lastName"
										placeholder={t("inputs.lastName.placeholder")}
										rules={rules.lastName}
									/>
								</InputWrapper>
								{countryMapping[methods.watch().countryCode]?.withCoops && (
									<InputWrapper>
										<SelectInput
											clearable
											control={methods.control}
											disabled={loading}
											initialOptions={coops.map((c) => ({
												label: c.name,
												value: c.id.toString()
											}))}
											label={t("inputs.coop.label")}
											name="coop"
											placeholder={t("inputs.coop.placeholder")}
											searchable
											theme="light"
										/>
										<InputTip palette={COLORS.DISABLED} withBorder={false}>
											<ColoredCardText>
												<Trans i18nKey="inputs.coop.inputTip">
													By linking your organism, you are authorizing their people to access
													your HYGO data. <br />
													If you&apos;re not concerned, select &quos;I&apos;m
													independant&quos;
												</Trans>
											</ColoredCardText>
										</InputTip>
									</InputWrapper>
								)}
								<Trans i18nKey="components.signUpForm.cgu">
									<CGU>
										By creating your accounting, you're agreeing with our
										<CGULink
											href="https://www.alvie.fr/mentions-legales"
											rel="noreferrer"
											target="_blank"
										>
											{" "}
											Terms of Use and Conditions
										</CGULink>
									</CGU>
								</Trans>
								<StyledButton
									color={COLORS.LAKE}
									disabled={isButtonDisabled}
									isSubmitBtn
									loading={loading}
									text={t("components.signUpForm.submitButton")}
								/>
							</>
						)}
					</Form>
				</FormProvider>
			</FormCard>
		</ContainerWithBgImage>
	);
};

export default SignupScreen;
